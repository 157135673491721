<template>
    <div class='index' :style="{height:heights}">
        <img class="loadingImg" src="../../src/assets/img/loading.png" alt="" :style="{width:loadingiconwidth}">
        <div class="bah_b">
            Copyright&nbsp;©&nbsp;2022-2023&nbsp;无锡正汉互联网医院有限公司版权所有<br><a style="color:#fff;"
                href="https://beian.miit.gov.cn/" target="_blank">备案号：苏ICP备2022012576号-1</a>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                heights: 0,
                loadingiconwidth: '',
                flagPc: true
            };
        },
        watch: {},
        computed: {},
        created() {},
        mounted() {
            this.getjx()
            this.getHeight()
        },
        methods: {
            getHeight() {
                let that = this
                that.heights = window.innerHeight + 'px'
                window.onresize = () => {
                    that.heights = window.innerHeight + 'px'
                }
            },
            getjx() {
                let userAgentInfo = navigator.userAgent;
                let Agents = [
                    'Android',
                    'iPhone',
                    'SymbianOS',
                    'Windows Phone',
                    'iPad',
                    'iPod'
                ];
                for (let i = 0; i < Agents.length; i++) {
                    if (userAgentInfo.indexOf(Agents[i]) > 0) {
                        this.flagPc = false;
                        break;
                    }
                }
                if (this.flagPc) {
                    this.loadingiconwidth = 400 + 'px'
                } else {
                    this.loadingiconwidth = 80 + '%'
                }
            }
        },
    };
</script>
<style lang="scss" scoped>
    .index {
        background-color: #0040AC;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;



        .loadingImg {}

        .bah_b {
          width: 100%;
            margin: 0 auto;
            padding: 20px 0;
            text-align: center;
            color: #fff;
            font-size: .22rem;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
</style>