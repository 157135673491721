<template>
    <div class="index">
        <div class="bgeee">
            <div class="same_width">
                <div class="bgfff">
                    <div class="banner_height"><img src="../../assets/img/image/news_banner.jpg"></div>
                    <div class="news_main2">
                        <h3 class="title">资讯中心</h3>
                        <div class="news_li">
                            <div class="newsBox u-f" v-for="(n,i) in list" :key="i" @click="gocontent(n)">

                                <div class="new_pic">
                                    <img :src="n.file[0].url" width="100%"
                                        onerror="this.src=&quot;/assets/img/defult_img.45374c87.png&quot;">
                                </div>
                                <div class="word_style">
                                    <div>
                                        <div class="wordtitle">{{n.name}}</div>
                                        <div class="worddes">{{n.describe}}</div>
                                    </div>

                                    <div class="createtime">{{n.create_time}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="page-box">
                            <el-pagination v-model:currentPage="currentPage" v-model:page-size="pageSize" background
                                @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                layout="prev, pager, next" :total="total" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import urlApi from '../../common/config'
    import seo from "../../common/seo"
    export default {
        metaInfo: {
            ...seo.news
        },
        components: {

        },
        data() {
            return {
                currentPage: 1,
                pageSize: 10,
                list: [],
                total: 0,

            }
        },
        created() {
            this.getlist()
        },
        mounted() {
            this.$root.Headerbgc = '#fff'
        },
        methods: {
            handleSizeChange(val) {},
            handleCurrentChange(val) {
                this.currentPage = val
                this.getlist()
            },
            getlist() {
                let params = {
                    article_type: 2,
                    page: this.currentPage,
                    page_count: this.pageSize
                }
                this.axios.post(urlApi.articleApi+'/admin/wss/health/Mlist', params).then(res => {
                    this.list = res.data.data.list
                    this.total = res.data.data.totalCount
                })
            },
            gocontent(n) {
                this.$router.push({
                    path: '/newsContent',
                    query: {
                        id: n.id
                    }
                })
            }
        }

    }
</script>

<style lang="scss" scoped>
    .index {
        .bgeee {
            background: #eee;
            padding-top: 120px;

            .same_width {
                width: 1200px;
                margin: 0 auto;

                .bgfff {
                    background: #fff;
                    padding-bottom: 100px;
                    text-align: left;

                    .banner_height {
                        width: 1200px;
                        height: 368px;
                        line-height: 368px;
                        text-align: center;
                        margin: 0 auto;

                        img {
                            width: auto;
                            max-width: 100%;
                            display: block;
                        }
                    }

                    .news_main2 {
                        padding: 50px 100px 0 100px;

                        .title {
                            color: #333;
                            font-size: 32px;
                            margin-bottom: 20px;
                        }

                        .news_li {
                            overflow: hidden;

                            // display: flex;
                            // flex-wrap: wrap;
                            .newsBox:last-child {
                                border: none;
                            }

                            .newsBox {
                                // width: 278px;
                                // height: 270px;
                                // margin-right: 80px;
                                // margin-bottom: 60px;
                                width: 100%;
                                padding: 20px 0;
                                border-bottom: 1px solid rgb(241, 241, 241);

                                .new_pic {
                                    width: 231px;
                                    height: 131px;
                                    overflow: hidden;
                                    position: relative;
                                    // line-height: 168px;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                        display: block;
                                        transition: transform 1.5s ease;
                                        object-fit: cover;
                                    }
                                }

                                .word_style {
                                    padding: 5px 16px 0;
                                    overflow: hidden;
                                    flex: 1;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-between;

                                    .wordtitle {

                                        font-size: 17px;
                                        color: #333;
                                        font-weight: 600;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        margin-bottom: 5px;
                                        // line-height: 30px;
                                    }

                                    .worddes {
                                        font-size: 14px;
                                        color: rgb(94, 94, 94);
                                        display: -webkit-box;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        -webkit-line-clamp: 3;
                                        -webkit-box-orient: vertical;

                                    }

                                    .createtime {
                                        color: rgb(94, 94, 94);

                                    }
                                }

                                img:hover {
                                    transform: scale(1.1);
                                }
                            }

                            .newsBox:nth-child(3n) {
                                margin-right: 0;
                            }

                        }

                        .page-box {
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
</style>