export default [
    {
        id: 7,
        text: '中医科',
        icon: require('../assets/img/image/ks/zyk.png')
      },


      {
        id: 2,
        text: '外科',
        icon: require('../assets/img/image/ks/wk.png')
      },
      {
        id: 3,
        text: '妇科',
        icon: require('../assets/img/image/ks/fck.png')
      },
      {
        id: 4,
        text: '皮肤科',
        icon: require('../assets/img/image/ks/pfk.png')
      }, {
        id: 1,
        text: '内科',
        icon: require('../assets/img/image/ks/nk.png')
      },
      {
        id: 5,
        text: '肿瘤科',
        icon: require('../assets/img/image/ks/zlk.png')
      },
      {
        id: 6,
        text: '康复医学科',
        icon: require('../assets/img/image/ks/yxk.png')
      },{
        id: 8,
        text: '精神科',
        icon: require('../assets/img/image/ks/jsk.png')
      },
]