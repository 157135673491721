<template>
    <div class="index">
        <div class="bgeee">
            <div class="same_width">
                <div class="banner_height"><img src="../../assets/img/phone/banner_yl.png"></div>
                <div class="bgfff">
                    <div class="q_item" v-for="(q,index) in doctors" :key="index" @click="opent">
                        <div class="q" :style="'background:url('+q.avatar+')'">
                        </div>
                        <div class="a">
                            <div class="a1">{{q.name}}</div>
                            <div class="a2">
                                <span>{{q.departName}} {{q.dictionaryName}}</span></div>
                            <div class="a3 van-multi-ellipsis--l2">{{q.beGoodAt}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <van-overlay :show="show" @click="show = false" z-index="1001">
            <div class="wrapper">
                <div class="block">
                    <div class="t">您可使用微信扫描小程序码联系医生</div>
                    <img src="../../assets/img/doctorList.png" alt="">
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import seo from "../../common/seo"
    // import doctors from "../../common/doctors"
    import urlApi from '../../common/config'
    export default {
        metaInfo: {
            ...seo.team
        },

        components: {},
        data() {
            return {
                doctors:[],
                
                show: false
            }
        },
        mounted() {
            this.$root.Headerbgc = '#fff'
            this.getdoclist()
        },
        methods: {
            opent() {
                this.show = true
            },
             getdoclist() {
                this.$toast.loading({
                    message:'正在加载',
                    forbidClick:true,
                    duration:0

                })
                this.axios.get(urlApi.nodeApi+`/pc/getDoctor`, {}).then(res => {
                    this.doctors = res.data.data.data
                    this.$toast.clear()
                })
            },
        }

    }
</script>

<style lang="scss" scoped>
    .index {
        .bgeee {
            background: #eee;

            .same_width {
                width: 100%;
                margin: 0 auto;

                .banner_height {
                    width: 100%;

                    img {
                        width: 100%;
                        display: block;
                    }
                }

                .bgfff {
                    background: #fff;
                    text-align: left;
                    overflow: hidden;
                    padding: 10px;

                    .q_item {
                        margin-bottom: .2rem;
                        display: flex;
                        align-items: center;
                        box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: .1);
                        border-radius: 5px;
                        padding: 0 .2rem;


                        .q {
                            width: 20%;
                            //    margin: .2rem;
                            box-sizing: border-box;
                            padding-top: 20%;
                            border-radius: 50%;
                            background-size: cover !important;
                            background-position: center !important;
                            background-repeat: no-repeat !important;
                        }

                        .a {
                            width: 80%;
                            padding: .15rem .15rem .15rem .22rem;
                            font-size: .25rem;
                            box-sizing: border-box;

                            .a1 {
                                font-size: .3rem;
                                font-weight: 600;
                                color: #000000;
                            }

                            .a2 {
                                color: #000000;

                                span {
                                    color: #999;
                                }

                                padding-bottom: .10rem;
                            }

                            .a3 {
                                color: #999;
                                width: 100%;
                                border-top: 1px solid rgb(226, 226, 226);
                                padding-top: .1rem;
                            }
                        }
                    }



                }
            }
        }

        .wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .block {
                width: 80%;
                background-color: #fff;
                border-radius: 5px;
                overflow: hidden;
                padding: 0 20px 10px;
                box-sizing: border-box;

                .t {
                    font-size: .28rem;
                    text-align: center;
                    padding: .25rem 0;
                }

                img {
                    width: 100%;
                    display: block;
                }
            }
        }
    }
</style>