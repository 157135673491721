<template>
  <div class="index">
    <!-- <Header :bgccolor="Headerbgc" /> -->
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide slide1 ">
          <img src="../../assets/img/image/banner/20230215-090105.jpg" alt="" width="100%">
          <!-- <div style="position: absolute;left: 24%;top:28%; z-index:10;">
            <div class="ani ani2-1">
              <h3 class=" ">正汉医生</h3>
              <h4 class=" ">互联网医疗服务平台</h4>
              <p>在线问诊 送药到家</p>
            </div>
          </div> -->
        </div>
        <div class="swiper-slide slide2">
          <img src="../../assets/img/image/banner/20230215-094401.jpg" alt="" width="100%">
          <!-- <div style="position: absolute;left: 24%;top:28%; z-index:10;">
            <div class="ani ani2-1">
              <h3 class=" ">正汉医疗</h3>
              <h4 class=" ">专业互联网医院建设和运营服务商</h4>
              <p>智慧医院 互联网诊疗</p>
            </div>
            <div class="fs16 white mt20 ml10 ani ani2-2">
            </div>
          </div> -->
        </div>
        <div class="swiper-slide slide3">
          <img src="../../assets/img/image/banner/20230215-094409.jpg" alt="" width="100%">
          <!-- <div style="position: absolute;left: 24%;top:28%; z-index:10;">
            <div class="ani ani3-1">
              <h3 class=" ">圆心药房</h3>
              <h4 class=" ">覆盖全国近300家医院的院边店药房</h4>
              <p>院边药房 全程服务</p>
            </div>
            <div class="fs16 white mt20 ml10 ani ani3-2">
            </div>
          </div> -->
        </div>
        <div class="swiper-slide slide4">
          <img src="../../assets/img/image/banner/10.jpg" alt="" width="100%">
          <!-- <div style="position: absolute;left: 24%;top:28%; z-index:10;">
            <div class="ani ani3-1">
              <h3 class=" ">圆心药房</h3>
              <h4 class=" ">覆盖全国近300家医院的院边店药房</h4>
              <p>院边药房 全程服务</p>
            </div>
            <div class="fs16 white mt20 ml10 ani ani3-2">
            </div>
          </div> -->
        </div>


        <!-- <div class="swiper-slide slide4">
          <img src="../../assets/img/image/banner/2-4.png" alt="" width="100%">
          <div style="position: absolute;left: 24%;top:28%; z-index:10;">
            <div class="ani ani4-1">
              <h3 class=" ">圆心惠保</h3>
              <h4 class=" ">创新型健康保险保障专业服务平台</h4>
              <p>惠民保险 全球特药</p>
            </div>
            <div class="fs16 white mt15 mr35 ani ani4-2" style="margin-right:35px;">
            </div>
          </div>
        </div> -->
      </div>
      <div class="swiper-button-next"></div>
      <div class="swiper-button-prev"></div>
      <div class="pagination"></div>
    </div>
    <div class="bgimg">


      <div class="h_jieshao">
        <h3>让患者更好地拥抱健康生活</h3>
        <p>
          我们不仅为患者提供更好的线上和线下就医体验还专注于专业的药事服务和持续的病程管理。<br>多元创新的支付方式为患者家庭提供更多选择，减轻经济负担。<br>通过由医生、药师、随访专家、研发人员组成的专业团队，解决诊疗和康复过程中的难点和困难，帮助患者更好地重获健康。
        </p>
      </div>
      <div class="mid_go_box">
        <div class="mid_go_inner">
          <div class="go_items">
            <div class="item_in_b item_in_bgimg1">
              <div class="g_con">
                <div class="g_i_n">线上复诊</div>
                <div class="g_i_t">优质专家，专业解答</div>
              </div>
              <div class="g_btns" @click="opxcx">
                去复诊<i class="icon iconfont icon-youjiantou-copy-copy icons_h"></i>
              </div>
            </div>
          </div>
          <div class="go_items">
            <div class="item_in_b item_in_bgimg2">
              <div class="g_con">
                <div class="g_i_n">预约挂号</div>
                <div class="g_i_t">直通院内专家</div>
              </div>
              <div class="g_btns" @click="opxcx">
                去挂号 <i class="icon iconfont icon-youjiantou-copy-copy icons_h"></i>
              </div>
            </div>
          </div>
          <div class="go_items">
            <div class="item_in_b item_in_bgimg3">
              <div class="g_con">
                <div class="g_i_n">健康咨询</div>
                <div class="g_i_t">随时随地解答</div>
              </div>
              <div class="g_btns" @click="opxcx">
                去咨询<i class="icon iconfont icon-youjiantou-copy-copy icons_h"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="kstjAndystj_b">
      <div class="kstjAndystj_b_inner">
        <div class="kstj_b" style="margin-bottom:50px">
          <div class="tj_header_tits">
            <div class="t_line"></div>
            <div class="t_text">科室推荐</div>
            <div class="t_line"></div>
          </div>
          <div class="ks_list_b">
            <div class="ks_item" v-for="(it,index) in ks_options" :key="index">
              <div class="imgs" :class="{imgfk:index==2}">
                <img :src="it.icon" :alt="it.text">
              </div>
              <div class="ks_name">{{it.text}}</div>
            </div>
          </div>
        </div>

        <div class="kstj_b">
          <div class="tj_header_tits">
            <div class="t_line"></div>
            <div class="t_text">医生推荐</div>
            <div class="t_line"></div>
          </div>
          <div class="ks_list_b">
            <div class="ys_item " v-for="(it,index) in doc_options" :key="index">
              <el-image style="width: 80px; height: 80px" class="imgs" :src="it.avatar" fit="cover" />
              <div class="d_info">
                <div class="d_name">{{it.name}}</div>
                <!-- <div class="d_zc">{{it.doctor_audit.competent_id_text}}</div> -->
              </div>
              <div class="d_yy">{{it.departName}} {{it.dictionaryName}}</div>
              <div class="d_sc">擅长：{{it.beGoodAt}}</div>
              <div class="xf_box" @click="showewm(it)">
                <div class="sj"></div>
                <div class="kuai">
                  在线<br>复诊
                </div>
              </div>
              <div class="fz_ewm_b" :class="{fz_e_ac:actives_id.indexOf(it.id)!=-1}">
                <img src="../../assets/img/gzh.png" alt="">
                您可使用微信扫描二维码关注公众号联系医生
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="home_warp">
      <div class="same_width">
        <div class="home_box" v-for="(it,index) in mod" :key="index">
          <div class="dflex1">
            <div>
              <img :src="it.icon" alt="">
            </div>
            <div class="flex1">
              <h4>{{it.tit1}}</h4>
              <p>{{it.tit2}}</p>
            </div>
          </div>
          <p class="dec">
            {{it.cons}}
          </p>
          <a href="" class="more_btn">查看更多</a>
        </div>
      </div>
    </div> -->

    <!-- <div class="news_main same_width">
      <div class="left_column">
        <div class="news_box"><img src="../../assets/img/image/zximgs.png">
          <div class="word_style">
            <h3>中国超3亿人睡眠障碍，拿什么拯救睡不着觉的你们?</h3>
            <div class="line"></div>
            <p class="dec"> 工作日整天996鸭梨山大，一到晚上辗转反侧百爪挠心，精神萎靡,茶饭不思统统找上门!焦虑、迷茫、抓狂，就是睡不着...... </p><a href="/"
              aria-current="page" class="check_more router-link-exact-active router-link-active">查看详情</a>
          </div>
        </div>
        <div class="bgCCC"></div>
      </div>
      <div class="right_column">
        <h2>新闻动态</h2>
        <ul>
          <li v-for="(it,index) in newslist" :key="index">
            <h3><img :src="it.icon" class="img_style"> {{it.text}} </h3>
          </li>
        </ul>
        <a href="/news" class="more_news">更多健康资讯 &gt;</a>
      </div>
    </div> -->
    <!-- <Footer /> -->
    <!-- 小程序 -->
    <el-dialog v-model="dialogXcx" title="" width="30%">
      <img style="width:100%;display:block;" src="../../assets/img/index.jpg" alt="">
    </el-dialog>
  </div>
</template>

<script>
  import seo from "../../common/seo"
  import doctors from "../../common/doctors"
  import departs from "../../common/depart"
  import urlApi from '../../common/config'
  export default {
    metaInfo: {
      ...seo.index
    },
    components: {
      // Footer,
      // Header
    },
    data() {
      return {
        a: 1,
        mod: [{
          id: 1,
          icon: require('../../assets/img/msys.png'),
          tit1: '妙手医生',
          tit2: '就医问诊',
          cons: '“妙手医生”系圆心科技的医患服务品牌，是医生和医生集团的互联网云端诊室，已取得医疗机构执业许可证，拥有行业内重要的“医、患、药”连接和服务能力。'
        }, {
          id: 2,
          icon: require('../../assets/img/yxyl.png'),
          tit1: '圆心医疗',
          tit2: '智慧平台',
          cons: '圆心医疗科技，帮助全国300多家医院建设并运营互联网医院服务平台，为南京、 哈尔滨等多个城市建设并运营健康城市服务平台。'
        }, {
          id: 3,
          icon: require('../../assets/img/yxyf.png'),
          tit1: '圆心药房',
          tit2: '用药管理',
          cons: '圆心药房目前已建设成安全、快捷、覆盖度高的本地用药配药网络。截至2021年8月31日覆盖全国30个省级行政区、91个重点城市的251家院边药房。'
        }, {
          id: 4,
          icon: require('../../assets/img/yxhb.png'),
          tit1: '圆心惠保',
          tit2: '创新支付',
          cons: '圆心惠保以专业赋能“医-患-药-险”健康保障生态闭环，提供一体化综合医疗健康保障服务解决方案。'
        }],
        newslist: [{
            id: 1,
            icon: require('../../assets/img/image/jkzx/1.png'),
            text: '30岁男性就勃起硬度不够，该怎么办?  ',
          },
          {
            id: 2,
            icon: require('../../assets/img/image/jkzx/2.png'),
            text: '汗出在哪儿，代表不同的问题!中医给你调理建议。',
          },
          {
            id: 3,
            icon: require('../../assets/img/image/jkzx/3.png'),
            text: '我国近1亿人患抑郁症，女性发病率是男性的2倍!关于抑郁症的4个真相，你要知道。 ',
          },
          {
            id: 4,
            icon: require('../../assets/img/image/jkzx/4.png'),
            text: '哪些问题可以导致女性尿道口有异样分泌物。',
          },
          {
            id: 5,
            icon: require('../../assets/img/image/jkzx/5.png'),
            text: '“开车”越多，丁丁越黑?',
          },
          {
            id: 6,
            icon: require('../../assets/img/image/jkzx/6.png'),
            text: '盘点春季最常见的4种皮肤病。',
          },
          {
            id: 7,
            icon: require('../../assets/img/image/jkzx/7.png'),
            text: '肿瘤发病的常见病因。',
          },

        ],
        Headerbgc: '',
        ks_options: [],
        doc_options: [],
        dialogXcx: false,
        actives_id: []
      }
    },
    beforeCreate() {},
    created() {
      this.ks_options = departs
    },
    beforeMount() {},
    mounted() {
      this.getSwiper()
      this.getdoc()
    },
    destroyed() {},
    activated() {
      this.handleScroll()
      window.addEventListener("scroll", this.handleScroll);
    },
    deactivated() {
      window.removeEventListener("scroll", this.handleScroll);

    },
    methods: {
      getdoc() {

        this.axios.get(urlApi.nodeApi + `/pc/getDoctor`, {}).then(res => {
        
          this.doc_options = res.data.data.data.filter((a, i) => i < 4)
        })

      },
      handleScroll() {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        if (scrollTop > 1) {
          this.$root.Headerbgc = '#fff'
        } else {
          this.$root.Headerbgc = ''
        }
      },
      getSwiper() {
        let that = this
        window.onload = function () {
          var mySwiper = new Swiper('.swiper-container', {
            effect: 'fade',
            autoplay: {
              delay: 5000, //1秒切换一次
            },
            speed: 400,
            // mode: 'vertical',
            resistance: '100%',
            loop: true,
            mousewheelControl: true,
            grabCursor: true,
            pagination: {
              el: '.pagination',
            },
            paginationClickable: true,
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
            on: {
              init: function () {
                $('.slide1').addClass('ani-slide');
              },
              transitionEnd: (swiper) => {
                $('.ani-slide').removeClass('ani-slide')
                $('.swiper-slide').eq(swiper.activeIndex).addClass('ani-slide')
              },
              click: (a) => {
                console.log();
                if (a.clickedIndex == 4) return that.$router.push('/newsContent?id=4884')


              }
            }
          })
        }
      },
      opxcx() {
        this.dialogXcx = true
      },
      showewm(it) {
        console.log(it);
        let index = this.actives_id.indexOf(it.id)
        if (index != -1) {
          this.actives_id.splice(index, 1)
        } else {
          this.actives_id.push(it.id)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../assets/css/style.css';

  .index {


    .swiper-container {

      // height: 678px;
      .pagination {
        position: absolute;
        text-align: center;
        transition: opacity .3s;
        transform: translateZ(0);
        z-index: 10;
      }

      :deep(.swiper-pagination-bullet) {
        width: 11px;
        height: 11px;
        border: 1px solid #b3b3b3;
        background-color: transparent;
        border-radius: 50%;
        // display: block;
        margin: 7px 7px 13px;
        cursor: pointer;
      }

      :deep(.swiper-pagination-bullet-active) {
        background: #b3b3b3;
      }

      .swiper-button-next {
        right: 10%;
        color: #666;
      }

      .swiper-button-prev {
        left: 10%;
        color: #666;
      }

    }

    .ani {
      h3 {
        font-size: 40px;
        color: #00aa96;
        line-height: 60px;
        margin-bottom: 5px;
        padding-right: 20px;
        font-weight: 700;
      }

      h4 {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 16px;
        color: #00aa96;
      }

      p {
        font-size: 25px;
        color: #000;
        padding-right: 40px;
        transition: 5s;
      }
    }

    @media screen and (min-width: 1600px) {
      .ani h3 {
        font-size: 60px;
        margin-bottom: 33px
      }

      .ani h4 {
        font-size: 38px
      }

      .ani p {
        font-size: 36px
      }
    }

    .bgimg {
      background-image: url('../../assets/img/image/btns/20230216-091248.jpg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .h_jieshao {
      text-align: center;

      h3 {
        font-size: 34px;
        color: #2B3D9D;
        font-weight: 600;
        line-height: 33px;
        padding: 60px 0 22px 0;
      }

      p {
        font-size: 16px;
        color: #8b8b8b;
        line-height: 26px;
        text-align: center;
        padding: 0 16px 50px 16px;
      }
    }

    .mid_go_box {
      width: 1200px;
      margin: 0 auto 0px;
      // background-image: url('../../assets/img/image/btns/b_du.png');
      background-size: contain;
      background-repeat: no-repeat;
      height: 268px;

      .mid_go_inner {
        width: 100%;
        display: flex;
        height: 100%;

        .go_items {
          width: calc(100%/3);
          display: flex;
          align-items: center;
          justify-content: center;

          .item_in_b {
            background-size: contain;
            background-repeat: no-repeat;
            width: 312px;
            height: 130px;
            // margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            .g_con {
              color: #fff;

              .g_i_n {
                font-size: 18px;
                font-weight: 600;
              }

              // .g_i_t {}
            }

            .g_btns {
              color: #fff;
              border-radius: 20px;
              border: 2px solid #fff;
              padding: 3px 25px;
              margin-left: 35px;
              cursor: pointer;
            }
          }

          .item_in_bgimg1 {
            background-image: url('../../assets/img/image/btns/d_1_1.png');
          }

          .item_in_bgimg2 {
            background-image: url('../../assets/img/image/btns/d_2_1.png');
          }

          .item_in_bgimg3 {
            background-image: url('../../assets/img/image/btns/d_3_1.png');
          }
        }
      }
    }

    .kstjAndystj_b {
      width: 100%;
      background-color: #F6F6F6;
      padding-bottom: 40px;

      .kstjAndystj_b_inner {
        width: 1200px;
        margin: 0 auto;


        .kstj_b {
          display: flex;
          flex-direction: column;
          align-items: center;

          .tj_header_tits {
            display: flex;
            align-items: center;
            padding: 40px 0;


            .t_line {
              width: 100px;
              height: 1px;
              background-color: rgb(146, 146, 146);
            }

            .t_text {
              font-size: 20px;
              font-weight: 600;
              margin: 0 10px;
              color: #2B3D9D;
              ;
            }
          }

          .ks_list_b {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;

            .ks_item {
              width: calc(100%/8);

              .imgs {
                width: 45px;
                // height: 55px;
                margin: 0 auto;


                img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              .imgfk {
                width: 30px;

              }

              .ks_name {
                width: 100%;
                text-align: center;
                margin-top: 10px;
                font-size: 16px;
                color: #979797;
              }
            }
          }

          .ys_item {
            width: 270px;
            padding: 0 5px;
            box-sizing: border-box;
            background-color: #fff;
            border: 2px solid #d1d1d1;
            height: 300px;
            border-radius: 2px;
            position: relative;

            .imgs {
              width: 40%;
              margin: 15px auto;
              border-radius: 50%;
              overflow: hidden;
              display: block;

              img {
                width: 100%;
                display: block;
              }
            }

            .d_info {
              display: flex;
              align-items: flex-end;
              justify-content: center;
              line-height: 1;

              .d_name {
                font-size: 18px;
                font-weight: 600;
              }

              .d_zc {
                margin-left: 10px;
                color: #7a7a7a;
              }
            }

            .d_yy {
              text-align: center;
              margin: 8px 0;
              color: #000;
            }

            .d_sc {
              border-top: 1px solid #d1d1d1;
              padding-top: 10px;
              color: #7a7a7a;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              word-break: break-all;
            }

            .xf_box {
              position: absolute;
              top: 33px;
              right: -11px;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              cursor: pointer;
              z-index: 3;

              .sj {
                border: 5px solid #165B95;
                border-top-color: transparent;
                border-right-color: transparent;
                width: 0px;
                height: 0px;
                border-top-left-radius: 3px;
              }

              .kuai {
                background-color: #6EB9FA;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 2px;
                padding: 5px 10px;
                color: #fff;
                line-height: 1.2;

              }
            }

            .fz_ewm_b {
              position: absolute;
              top: 0px;
              left: 0;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background-color: #fff;
              z-index: 2;
              opacity: 0;
              transform: scale(0);
              transition: all 0.3s;


              img {
                width: 80%;
                display: block;
              }
            }

            .fz_e_ac {
              opacity: 1;
              transition: all 0.3s;
              transform: scale(1);
            }


          }
        }
      }
    }

    .home_warp {
      background: #00aa96;

      .same_width {
        width: 1200px;
        margin: 0 auto;
        display: flex;

        .home_box {
          padding: 130px 0 120px 0;
          text-align: left;
          color: #fff;
          font-size: 14px;
          flex: 1;
          margin-right: 100px;
          position: relative;

          .dflex1 {
            display: flex;

            img {
              width: 65px;
              height: 65px;
              margin-right: 15px;
            }
          }

          .flex1 {
            flex: 1;

            h4 {
              font-size: 24px;
              font-weight: 500;
              margin-top: 4px;
            }

            p {
              font-size: 14px;
              margin: 8px 0 0 0;
              line-height: 26px;
            }
          }

          .dec {
            line-height: 24px;
            margin: 20px 0;
          }

          .more_btn {
            height: 30px;
            line-height: 30px;
            border: 1px solid hsla(0, 0%, 100%, .4);
            font-size: 14px;
            display: block;
            color: #fff;
            text-align: center;
            width: 80px;
            border-radius: 30px;
            position: absolute;
            bottom: 100px;
            left: 0;
          }
        }
      }
    }

    .news_main {
      font-size: 14px;
      text-align: left;
      padding-bottom: 15px;
      margin-top: 60px;
      margin-bottom: 60px;

      .left_column {
        width: 424px;
        position: relative;
        height: 520px;

        .news_box {
          border: 1px solid #e7e7e7;
          position: relative;
          background: #fff;
          z-index: 10;
          height: 100%;

          img {
            width: 424px;
            height: 254px;
          }

          .word_style {
            padding: 28px 28px 0 28px;

            h3 {
              font-size: 20px;
              color: #000;
              line-height: 26px;
            }

            .line {
              background: #999;
              width: 71px;
              height: 2px;
              margin: 20px 0;
            }

            .dec {
              height: 50px;
              overflow: hidden;
              font-size: 14px;
              line-height: 26px;
              color: #666;
            }

            .check_more {
              border: 1px solid #dadcdc;
              width: 80px;
              height: 28px;
              border-radius: 30px;
              font-size: 14px;
              color: #000;
              line-height: 28px;
              text-align: center;
              display: inline-block;
              margin-top: 30px;
            }

          }
        }

        .bgCCC {
          background: #e7e7e7;
          position: absolute;
          left: 18px;
          top: 50px;
          width: 100%;
          height: 95%;
        }
      }

      .right_column {
        margin-left: 80px;

        li {
          color: #666;
          font-size: 16px;
          margin-bottom: 30px;
          cursor: pointer;

          .img_style {
            width: 40px;
            // height: 24px;
            vertical-align: middle;
          }
        }
      }

      h2 {
        font-size: 24px;
        font-weight: 700;
        color: #000;
        padding-bottom: 30px;
        background: #fff;
      }
    }

    .same_width {
      width: 1200px;
      margin: 60px auto;
      display: flex;
    }


    .icons_h {
      color: #fff;

      font-size: 14px;
    }
  }
</style>