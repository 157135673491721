<template>
    <div class="kang">
        <van-nav-bar title="您的专属助理：小康" left-arrow :fixed="true" :placeholder="true"
            @click-left="prev=> this.$router.go(-1)">
        </van-nav-bar>
        <div class="cont">
            <div class="codebox">
                <div class="tit">
                    任何问题均可咨询，正汉互联网医院<br>24小时人工客服，竭诚为您服务！
                </div>
                <div class="codeimg">
                    <img src="../../assets/img/gzh.png" alt="">
                </div>
                <div class="tit2">
                    扫码添加小康微信<br>
                    一对一在线服务您
                </div>
                <div class="btn_b">
                    <van-button @click="savecode" class="btnstyle" block type="primary">长按保存二维码</van-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        mounted() {

        },
        methods: {
            savecode() {
                this.$toast('长按保存二维码')
            }
        }
    }
</script>

<style lang="scss" scoped>
.kang{
    width: 100%;
}
    .cont {
        padding: .28rem;

        .codebox {
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0 0 4px rgb(223, 223, 223);

            .tit {
                padding: 1rem 0 .5rem;
                text-align: center;
                font-size: .3rem;
            }
            .tit2{
                text-align: center;
                font-size: .3rem;

            }

            .codeimg {
                // width: 100%;
                padding: 0 1.2rem;

                img {
                    width: 100%;
                }
            }

            .btn_b {
                padding: .75rem;

                .btnstyle {
                    font-size: .32rem;
                    border-radius: 5px;
                }
            }
        }
    }
</style>