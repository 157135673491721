<template>
    <div class="footer-wrap">
        <div class="footer-content">
            <div class="e_b_out">
                <div class="e_b">
                    <div class="e_img">
                        <img src="../../../assets/img/gzh.png" alt="">
                    </div>
                    <div class="e_text">正汉互联网医院<br>公众号</div>
                </div>
                <div class="e_b">
                    <div class="e_img">
                        <img src="../../../assets/img/index.jpg" alt="">
                    </div>
                    <div class="e_text">正汉互联网医院<br>小程序</div>
                </div>
                <div class="e_b">
                    <div class="e_img">
                        <img src="../../../assets/img/docd.png" alt="">
                    </div>
                    <div class="e_text">正汉医生app下载<br></div>
                </div>
            </div>
            <div class="r_b_out">
                <div class="r1">
                    <div class="r_tit">正汉互联网医院</div>
                    <div class="r_text">地址：江苏省无锡市滨湖区隐秀路813号A座7楼</div>
                    <div class="r_text">电话：0510-82721166 <span style="margin-left:20px">网址：www.zhhlwyy.cn</span> </div>
                </div>
                <!-- <div class="r2">
                    <div class="r_text">网址：www.zhhlwyy.cn</div>
                </div> -->
            </div>


        </div>
        <div class="bah_b">
            Copyright&nbsp;©&nbsp;2022-2023&nbsp;无锡正汉互联网医院有限公司版权所有<br><a style="color:#fff;"
                href="https://beian.miit.gov.cn/" target="_blank">备案号：苏ICP备2022012576号-1</a><br>

            <div class="gnbn">
                <img src="../../../assets/img/Registration_icon.png" alt=""><a
                    href="https://beian.mps.gov.cn/#/query/webSearch?code=32021102002429" rel="noreferrer"
                    target="_blank">苏公网安备32021102002429号</a></div>
        </div>
    </div>
</template>

<script>
    export default {

    }
</script>

<style lang="scss" scoped>
    .footer-wrap {
        width: 100%;
        z-index: 1000;
        background-image: url('../../../assets/img/image/foo_img.png');
        background-size: cover;
        background-repeat: no-repeat;


        .footer-content {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;
            display: flex;
            align-items: center;

            .e_b_out {
                display: flex;
                justify-content: space-between;
                width: 45%;
            }

            .e_b {
                .e_img {
                    width: 135px;

                    img {
                        width: 100%;
                        display: block;
                    }
                }

                .e_text {
                    color: #fff;
                    text-align: center;
                    margin-top: 10px;
                }
            }

            .r_b_out {
                display: flex;
                align-items: flex-end;
                color: #fff;
                margin-left: 50px;

                .r1 {
                    .r_tit {
                        font-size: 22px;
                        font-weight: 600;
                    }
                }

                .r_text {
                    margin-top: 5px;
                    font-size: 16px;
                }

                .r2 {
                    margin-left: 50px;
                }
            }



        }

        .bah_b {
            width: 1200px;
            margin: 0 auto;
            padding: 20px 0;
            text-align: center;
            color: #fff;

            .gnbn {
                img {
                    width: 16px;
                    margin-right: 3px;
                }

                a {
                    color: #fff;
                }

            }
        }


    }
</style>