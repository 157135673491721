export default [{
        id: 34,
        author_avatar: require('../assets/img/docs/scq.jpg'),
        author_text: '孙承祺',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '主任中医师',
        },
        doctor_info: {
            good_at: '擅长：中医治疗各种肿瘤，尤其肺、肝、胃、乳腺等肿瘤。对内科疑难杂病、外科疑难杂病、呼吸、消化系统疑难病、老年病、男科病、妇科杂病临床经验丰富。'
        }
    },
    {
        id: 35,
        author_avatar: require('../assets/img/docs/hjm.jpg'),
        author_text: '贺金民',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '主治医生',
        },
        doctor_info: {
            good_at: '擅长：男性勃起功能障碍、性欲亢进、性欲冷淡、早泄 (射精过快)、射精疼痛、阴茎易疲软、弱精症、龟头敏感、遗精病、血精症、不射精症、手淫后遗症 (易疲劳、记忆力下降、阴茎勃起缓慢、排尿费力、尿不尽等)、男性更年期综合症、男性性冷淡、亚健康、睾丸炎、淋证 (慢性前列腺炎)、阴囊潮湿等多种男科疑难杂症。'
        }
    },
    //  {
    //     id: 36,
    //     author_avatar: require('../assets/img/docs/zjb.jpg'),
    //     author_text: '朱剑波',
    //     department: '中医科',
    //     departmentId: '14',
    //     doctor_audit: {
    //         competent_id_text: '主治医生',
    //     },
    //     doctor_info: {
    //         good_at: '擅长：临床擅长前列腺疾病，男性性腺功能低下，性功能障碍，男性不育等内科杂症的中医治疗。'
    //     }
    // },
    // {
    //     id: 39,
    //     author_avatar: require('../assets/img/docs/cp.jpg'),
    //     author_text: '陈平',
    //     department: '中医科',
    //     departmentId: '14',
    //     doctor_audit: {
    //         competent_id_text: '执业中医师',
    //     },
    //     doctor_info: {
    //         good_at: '擅长：治疗多种常见、高发男科疾病，如阳痿、早泄等男性功能障碍，急慢性前列腺炎，多种原因引起的性欲异常、射精异常，男性生长发育问题、更年期问题，以及复杂原因的男性不育等疑难杂症。'
    //     }
    // },

  {
        id: 40,
        author_avatar: require('../assets/img/docs/mh.jpg'),
        author_text: '马红',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '执业中医师',
        },
        doctor_info: {
            good_at: '擅长：男性勃起功能障碍(阳痿)、早泄、男性不育(少弱畸精子症)、男性迟发型性腺功能减退(男性衰老)、慢性前列腺炎、慢性盆腔疼痛综合征诊治及健康管理。'
        }
    },


    {
        id: 1,
        author_avatar: require('../assets/img/docs/fanyulin.jpg'),
        author_text: '范玉林',
        department: '内科',
        departmentId: '1',
        doctor_audit: {
            competent_id_text: '副主任医师',
        },
        doctor_info: {
            good_at: '擅长：慢性胃炎、消化性溃疡、胰腺炎、肝硬化、消化道出血等消化系统疾病。'
        }
    },

    // {
    //     id: 2,
    //     author_avatar: require('../assets/img/docs/hufenglin.jpg'),
    //     author_text: '虎峰林 ',
    //     department: '泌尿外科',
    //     departmentId: '3',
    //     doctor_audit: {
    //         competent_id_text: '主治医师',
    //     },
    //     doctor_info: {
    //         good_at: '擅长：勃起功能障碍，阳痿、早泄、性欲低下等男科疾病。'
    //     }
    // },

    // {
    //     id: 3,
    //     author_avatar: require('../assets/img/docs/dongweihang.jpg'),
    //     author_text: '董伟航',
    //     department: '中医科',
    //     departmentId: '14',
    //     doctor_audit: {
    //         competent_id_text: '主治中医师',
    //     },
    //     doctor_info: {
    //         good_at: '擅长：男性功能障碍（阳痿、早泄、不射精、性欲异常等），前列腺疾病（慢性前列腺炎、良性前列腺增生等），男性不育症（少弱畸精症、无精症、精液不液化等）。'
    //     }
    // },
    // {
    //     id: 4,
    //     author_avatar: require('../assets/img/docs/lixiaowei.jpg'),
    //     author_text: '李晓伟',
    //     department: '中医科',
    //     departmentId: '14',
    //     doctor_audit: {
    //         competent_id_text: '副主任中医师',
    //     },
    //     doctor_info: {
    //         good_at: '擅长：前列腺炎、前列腺增生、早泄、阳痿、男性不育等男科疾病的诊疗。'
    //     }
    // },
    // {
    //     id: 5,
    //     author_avatar: require('../assets/img/docs/zrf.jpg'),
    //     author_text: '郑锐峰',
    //     department: '泌尿外科',
    //     departmentId: '3',
    //     doctor_audit: {
    //         competent_id_text: '主治医师',
    //     },
    //     doctor_info: {
    //         good_at: '擅长：主要研究男性功能障碍、前列腺疾病、男性泌尿系统疾病等，特别对少精、弱精、死精、无精、不射精、逆行射精，及睾丸发育不良等疑难病症，研究总结并独有建树。'
    //     }
    // },

    {
        id: 6,
        author_avatar: require('../assets/img/docs/duanzhanguo.jpg'),
        author_text: '段占国 ',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '主治中医师 ',
        },
        doctor_info: {
            good_at: '擅长：阳痿等性功能障碍，前列腺性疾病，男性不育症等男科疾病'
        }
    },

    {
        id: 7,
        author_avatar: require('../assets/img/docs/wdc.jpg'),
        author_text: '王德超',
        department: '泌尿外科',
        departmentId: '3',
        doctor_audit: {
            competent_id_text: '主治医师',
        },
        doctor_info: {
            good_at: '擅长：阳痿、早泄等男性功能障碍，前列腺疾病、男性不育症、泌尿生殖感染等'
        }
    },
    // {
    //     id: 8,
    //     author_avatar: require('../assets/img/docs/zjw.jpg'),
    //     author_text: '赵继伟',
    //     department: '泌尿外科',
    //     departmentId: '3',
    //     doctor_audit: {
    //         competent_id_text: '主治医师',
    //     },
    //     doctor_info: {
    //         good_at: '擅长：男性前列腺疾病，男性性功能障碍，不育症，泌尿系统疾病等外科疾病'
    //     }
    // },
    {
        id: 9,
        author_avatar: require('../assets/img/docs/lhe.jpg'),
        author_text: '刘浩恩',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '主治中医师',
        },
        doctor_info: {
            good_at: '擅长：中西医结合诊疗阳痿、早泄、前列腺炎、血精、阴汗、不育症等男科疾病，对男性亚健康中医调理有独到见解。'
        }
    },
    {
        id: 10,
        author_avatar: require('../assets/img/docs/jyy.jpg'),
        author_text: '姜昱炎',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '主治中医师',
        },
        doctor_info: {
            good_at: '擅长：前列腺炎、前列腺增生、早泄、阳痿、男性不育等男科疾病的诊疗。'
        }
    },
    {
        id: 11,
        author_avatar: require('../assets/img/docs/chy.jpg'),
        author_text: '陈海燕',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '副主任中医师',
        },
        doctor_info: {
            good_at: '擅长：手术与中药调理相结合的方法治疗泌尿系统疾病，对不孕不育、急慢性前列腺炎、性功能障碍等男性疾病有独到的诊治经验。'
        }
    },
    {
        id: 12,
        author_avatar: require('../assets/img/docs/xfl.jpg'),
        author_text: '徐方丽',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '主治中医师',
        },
        doctor_info: {
            good_at: '擅长：中医药调理男/妇科类疾病，特别是月经不调、卵巢功能减退、卵巢囊肿、宫腔残留等妇科类疾病，以及男性肾虚，男性功能障碍，前列腺问题，男性不育，男性维养等男科类疾病'
        }
    },
    {
        id: 13,
        author_avatar: require('../assets/img/docs/bxx.jpg'),
        author_text: '白新星',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '主治中医师',
        },
        doctor_info: {
            good_at: '擅长：中医调理肾病、肝病，内分泌紊乱导致的皮肤疾病及疑难杂症。'
        }
    },
    {
        id: 14,
        author_avatar: require('../assets/img/docs/fq.jpg'),
        author_text: '付琦',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '主治中医师',
        },
        doctor_info: {
            good_at: '擅长：中医药治疗痛经、月经不调、妇科炎症、子宫肌瘤、卵巢囊肿、子宫内膜异位症、习惯性流产、不孕症等多种妇科常见疾病与疑难杂症。'
        }
    },
    {
        id: 15,
        author_avatar: require('../assets/img/docs/gj.jpg'),
        author_text: '高峻',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '副主任中医师',
        },
        doctor_info: {
            good_at: '擅长：肾脏病、血液净化的中西医结合治疗。师从黄煌教授学习经方，善用经方治疗各种杂病，以及亚健康调理。'
        }
    },
    {
        id: 16,
        author_avatar: require('../assets/img/docs/gzg.jpg'),
        author_text: '郭正刚',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '副主任中医师',
        },
        doctor_info: {
            good_at: '擅长：中医肝病 (慢性肝炎、肝脏肿瘤术后调理，及脂肪肝、酒精肝等亚健康问题 ) ，肾脏病、血液净化的中西医结合治疗。师从黄煌教授学习经方，善用经方治疗各种杂病，以及亚健康调理。'
        }
    },
    {
        id: 17,
        author_avatar: require('../assets/img/docs/wtl.jpg'),
        author_text: '吴天麟',
        department: '泌尿外科',
        departmentId: '3',
        doctor_audit: {
            competent_id_text: '主任医师',
        },
        doctor_info: {
            good_at: '擅长：对早泄、阳痿、性欲低下等常见性功能障碍，对不射精、逆行射精、射精疼痛等射精功能障碍的诊疗，以及对多种肾上腺疾病、泌尿生殖系统肿瘤、肾脏移植等疑难杂症，均有丰富理论基础和临床经验。'
        }
    },
    {
        id: 18,
        author_avatar: require('../assets/img/docs/cq.jpg'),
        author_text: '陈群',
        department: '泌尿外科',
        departmentId: '3',
        doctor_audit: {
            competent_id_text: '主任医师',
        },
        doctor_info: {
            good_at: '擅长：擅长前列腺疾病、生殖感染、生殖整形，及阳痿、早泄、勃起功能障碍、射精功能障碍等男性疾病的治疗'
        }
    },
    {
        id: 19,
        author_avatar: require('../assets/img/docs/wj.jpg'),
        author_text: '王健',
        department: '泌尿外科',
        departmentId: '3',
        doctor_audit: {
            competent_id_text: '副主任医师',
        },
        doctor_info: {
            good_at: '擅长：泌尿外科微创手术和阴茎三件套支撑体植入手术，如经尿道前列腺钬激光剜除术，经尿道前列腺电切术，输尿管硬镜及软镜下钬激光碎石，腹腔镜下肾癌根治术，腹腔镜下肾上腺肿切除术，腹腔镜下输尿管切开取石术，腹腔镜下全膀胱切除术，腹腔镜前列腺癌根治术，膀胱肿瘤电切术，经腹腔镜下精索静脉高位结扎术等等。'
        }
    },
    {
        id: 20,
        author_avatar: require('../assets/img/docs/wq.jpg'),
        author_text: '王强',
        department: '泌尿外科',
        departmentId: '3',
        doctor_audit: {
            competent_id_text: '主任医师',
        },
        doctor_info: {
            good_at: '擅长：多种原因引起的射精功能障碍、男性性腺功能衰退，早泄、阳痿、性欲低下等男性功能障碍，及多种前列腺疾病、包皮龟头炎、精囊疾病等疑难病症的诊疗。'
        }
    },
    {
        id: 21,
        author_avatar: require('../assets/img/docs/lts.jpg'),
        author_text: '刘铁石',
        department: '泌尿外科',
        departmentId: '3',
        doctor_audit: {
            competent_id_text: '副主任医师',
        },
        doctor_info: {
            good_at: '擅长：熟练掌握本专业疾病的诊断和治疗，对泌尿系统疑难杂症有丰富的临床经验各种泌尿外科腹腔镜及开放手术，特别是腹腔镜下肾肿瘤、肾上腺肿瘤、前列腺肿瘤、膀胱肿瘤根治术及肾脏移植等高难度泌尿外科手术。常规开展经皮肾镜或输尿管镜治疗泌尿系统结石，前列腺增生肥大铁激光等微创腔内治疗。'
        }
    },
    {
        id: 22,
        author_avatar: require('../assets/img/docs/ltf.jpg'),
        author_text: '李天赋',
        department: '泌尿外科',
        departmentId: '3',
        doctor_audit: {
            competent_id_text: '主治医师',
        },
        doctor_info: {
            good_at: '擅长：男性不育症、男性性功能障碍以及其他男科常见疾病的诊疗，已独立完成附睾、睾丸穿刺取精逾百例。'
        }
    },
    {
        id: 23,
        author_avatar: require('../assets/img/docs/zzl.jpg'),
        author_text: '纵兆利',
        department: '泌尿外科',
        departmentId: '3',
        doctor_audit: {
            competent_id_text: '主任医师',
        },
        doctor_info: {
            good_at: '擅长：对急慢性前列腺炎、精囊炎、膀胱炎、早泄、阳痿、男性不育等男性功能障碍，以及泌尿系结石、泌尿系肿瘤、生殖畸形等多种男性疾病，临床经验极为丰富。'
        }
    },
    {
        id: 24,
        author_avatar: require('../assets/img/docs/lyx.jpg'),
        author_text: '刘玉星',
        department: '妇科',
        departmentId: '6',
        doctor_audit: {
            competent_id_text: '副主任医师',
        },
        doctor_info: {
            good_at: '擅长：女性不孕，及妇科炎症、输卵管阻塞、内分泌失调等妇科疾病。'
        }
    },
    {
        id: 25,
        author_avatar: require('../assets/img/docs/ywx.jpg'),
        author_text: '杨文霞',
        department: '妇科',
        departmentId: '6',
        doctor_audit: {
            competent_id_text: '副主任医师',
        },
        doctor_info: {
            good_at: '擅长：妇科常见病、多发病的诊断与治疗'
        }
    },


    {
        id: 26,
        author_avatar: require('../assets/img/docs/myy.jpg'),
        author_text: '毛玉燕',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '主任中医师',
        },
        doctor_info: {
            good_at: '擅长：儿童急慢性咳嗽、过敏性鼻炎、哮喘、腺样体肥大、慢性胃肠功能紊乱、厌食、腹痛、便秘、性早熟矮小、睡眠障碍、多动抽动等疾病的治疗。'
        }
    },
    {
        id: 27,
        author_avatar: require('../assets/img/docs/xh.jpg'),
        author_text: '肖寒',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '主任中医师',
        },
        doctor_info: {
            good_at: '擅长：利用中医治疗肺癌、甲状腺结节，乳腺结节，乳腺癌、胃肠道肿瘤等常见恶性肿瘤。擅用中医配合西药进行恶性肿瘤的系统治疗与康复。'
        }
    },
    {
        id: 28,
        author_avatar: require('../assets/img/docs/zh.jpg'),
        author_text: '赵辉',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '副主任中医师',
        },
        doctor_info: {
            good_at: '擅长：以中医药治疗慢性结肠炎、便秘及胃肠功能紊乱等慢性病，对不明原因便血，及胃肠道肿瘤的各类消化系统、肛肠系统常见病。'
        }
    },
    {
        id: 29,
        author_avatar: require('../assets/img/docs/yk.jpg'),
        author_text: '杨凯',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '主治中医师',
        },
        doctor_info: {
            good_at: '擅长：常见男科疾病如前列腺疾病（前列腺炎、前列腺增生、前列腺癌等）、性功能障碍疾病（阳痿、早泄、性欲下降、不射精等）、男性不育症（少精、弱精、无精、死精等）、附属性腺疾病（睾丸炎、附睾炎、精索静脉曲张、包皮龟头炎等）、性病（淋病、非淋、尖锐湿疣、生殖器疱疹、梅毒等）。'
        }
    },
    // {
    //     id: 30,
    //     author_avatar: require('../assets/img/docs/yqq.jpg'),
    //     author_text: '杨清泉',
    //     department: '中医科',
    //     departmentId: '14',
    //     doctor_audit: {
    //         competent_id_text: '主治中医师',
    //     },
    //     doctor_info: {
    //         good_at: '擅长：独到的“中医免疫疗法”系统入手从根论治各种疑难杂病，在中医药治疗睡眠障碍问题如失眠、难寐、易醒、眠浅、早醒、睡眠多梦健忘，及压力下磨牙、梦呓、梦魇等病例中尤为精到；其综合调理免疫技术在男科领域如男性肾虚、阳痿、早泄、性欲异常、前列腺问题、射精异常、男性不育等应用中，指导各类轻、重症临床研究，长期跟进病例反馈颇佳。'
    //     }
    // },
    {
        id: 31,
        author_avatar: require('../assets/img/docs/slh.jpg'),
        author_text: '沈利华',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '副主任中医师',
        },
        doctor_info: {
            good_at: '擅长：擅长诊治呼吸慢支、哮喘、消化系统疾病、慢性肾炎及生殖泌尿系统疾病、皮肤病、肿瘤等疑难杂症。'
        }
    },
    {
        id: 32,
        author_avatar: require('../assets/img/docs/gqy.jpg'),
        author_text: '过秋燕',
        department: '精神科',
        departmentId: '15',
        doctor_audit: {
            competent_id_text: '副主任医师',
        },
        doctor_info: {
            good_at: '擅长：治疗因神经功能调节异常引起的疾病如:常见各年龄段抑郁症、强迫症、焦虑症、失眠症、植物神经功能紊乱、神经官能症，以及成年人亚健康、偏头痛、心理障碍、神经性头痛、长期头晕、长期疲劳、老年痴呆等疑难杂症'
        }
    },
    {
        id: 33,
        author_avatar: require('../assets/img/docs/zqm.jpg'),
        author_text: '朱其敏',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '主治中医师',
        },
        doctor_info: {
            good_at: '擅长：原发性、继发性早泄；动脉、静脉性阳痿，糖尿病性阳痿，高血压性阳痿，内分泌性阳痿；不射精、逆行射精等射精障碍；血精、死精、弱精、无精等精液异常问题；以及睾丸、会阴疼痛等顽固性疼痛。'
        }
    },

    {
        id: 37,
        author_avatar: require('../assets/img/docs/ztl.jpg'),
        author_text: '翟红军',
        department: '泌尿外科',
        departmentId: '3',
        doctor_audit: {
            competent_id_text: '主治医师',
        },
        doctor_info: {
            good_at: '擅长：治疗多种常见、高发男科疾病，如阳痿、早泄等男性功能障碍，急慢性前列腺炎，多种原因引起的性欲异常、射精异常，男性生长发育问题、更年期问题，以及复杂原因的男性不育等疑难杂症。'
        }
    },

    {
        id: 38,
        author_avatar: require('../assets/img/docs/lgl.jpg'),
        author_text: '吕观玲',
        department: '中医科',
        departmentId: '14',
        doctor_audit: {
            competent_id_text: '主治中医师',
        },
        doctor_info: {
            good_at: '擅长：中药治疗生殖相关疾病，包括女性痛经，月经不调，男性ED，性功能障碍等。中药治疗感冒及病毒性感冒后遗症，如新冠后遗症引起的心悸，心区疼痛。中药调理亚健康问题，如失眠，皮肤痤疮，以及胃动力不良，关节痹症等。整合医学疗法干预代谢相关类疾病，如糖尿病前期，高血压1期，多囊卵巢综合征，胰岛素抵抗综合征等。'
        }
    },


]