<template>
    <div class="removeHome">
        <div class="t-suspend-button" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd"
            ref="remove" :style="`left: ${oLeft}px; top: ${oTop}px;`">
            <div class="yuanqiu" @click="gopage">
                <img :src="img" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            img: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                oLeft: "",
                oTop: "",
                $vm: null, // 悬浮按钮
                moving: false, // 移动状态
                oW: null, // 悬钮距离
                oH: null,
                htmlWidth: null, // 页面宽度
                htmlHeight: null,
                bWidth: null, // 悬钮宽度
                bHeight: null,
                click: false // 是否是点击
            };
        },
        mounted() {
            this.$refs.remove.addEventListener(
                "touchmove",
                e => {
                    if (e.cancelable) {
                        e.preventDefault();
                    }
                }, {
                    passive: false
                }
            );
        },
        methods: {
            gopage(){
                this.$emit('gopage')
            },
            // 移动触发
            onTouchStart(e) {
                e = e.touches[0];
                this.click = true;
                this.oW = e.clientX - this.$refs.remove.getBoundingClientRect().left;
                this.oH = e.clientY - this.$refs.remove.getBoundingClientRect().top;

                // console.log("e.clientX宽", e.clientX, "e.clientY高", e.clientY);

                // console.log(
                //     "移动宽",
                //     this.$refs.remove.getBoundingClientRect().left,
                //     "移动高",
                //     this.$refs.remove.getBoundingClientRect().top
                // );

                this.htmlWidth = document.documentElement.clientWidth;
                this.htmlHeight = document.documentElement.clientHeight;

                // console.log("body宽", this.htmlWidth, "body高", this.htmlHeight);
                this.bWidth = this.$refs.remove.offsetWidth;
                this.bHeight = this.$refs.remove.offsetHeight;

                // console.log("a宽", this.oW, "a高", this.oH);

                let oLeft = e.clientX - this.oW;
                let oTop = e.clientY - this.oH;

                this.oLeft = oLeft;
                this.oTop = oTop;

                this.moving = true;
            },
            // 移动结束
            onTouchEnd(e) {
                this.moving = false;

                // this.$refs.remove.class + " t-suspend-button-animate";
                this.$refs.remove.classList.add("t-suspend-button-animate")

                // 左侧距离
                let oLeft = this.oLeft;

                if (oLeft < (this.htmlWidth - this.bWidth) / 2) {
                    oLeft = 0;
                } else {
                    oLeft = this.htmlWidth - this.bWidth;
                }

                // if (this.click) {
                //   this.props.onClick();
                // }
                // }
                // let oTop = this.oTop
                // if (oTop < 0) {
                //     oTop = 0;
                // } else if (oTop > this.htmlHeight - this.bHeight) {
                //     oTop = this.htmlHeight - this.bHeight;
                // }
                this.oLeft = oLeft;
                //  this.oTop = oTop;
            },
            // 开始移动
            onTouchMove(e) {
                this.$refs.remove.classList.remove("t-suspend-button-animate")
                this.$refs.remove.class = "t-suspend-button";
                this.moving && this.onMove(e);
            },
            // 移动中
            onMove(e) {
                e = e.touches[0];
                this.click = false;

                // 左侧距离
                let oLeft = e.clientX - this.oW;
                let oTop = e.clientY - this.oH;
                // console.log("移动左距离", oLeft, "移动上距离", oTop);
                if (oLeft < 0) {
                    oLeft = 0;
                } else if (oLeft > this.htmlWidth - this.bWidth) {
                    oLeft = this.htmlWidth - this.bWidth;
                }
                if (oTop < 0) {
                    oTop = 0;
                } else if (oTop > this.htmlHeight - this.bHeight) {
                    oTop = this.htmlHeight - this.bHeight;
                }

                this.oLeft = oLeft;
                this.oTop = oTop;
            }
        }
    };
</script>

<style lang="scss" scoped>
    .removeHome {
        .t-suspend-button {
            position: fixed;
            bottom: 165px;
            right: 0px;
            width: 55px;
            height: 55px;
            border-radius: 2rem;
            z-index: 99999;
        }

        .t-suspend-button-animate {
            transition: all .3s;
        }

        .yuanqiu {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-radius: 50%;
            box-shadow: 0 0 5px 0 #d8d8d8;

            img {
                width: 70%;
                display: block;
            }
        }
    }
</style>