<template>
    <div class="index">
        <div class="screen-wrap">
            <div class="content-wrap">
                <div class="top-wrap"><img class="insurance-banner" data-src="../../assets/img/jiuyi/seekDoctor1.png"
                        src="../../assets/img/jiuyi/seekDoctor1.png" lazy="loaded">

                </div>
                <div class="insurance-wrap u-f">
                    <div class="insurance-item">
                        <div class="m-title">患者服务</div>
                        <div class="pure-text mt-20">可享受全国一线医院的专家服务，进行在线开方购药，可实现足不出户、送药到家。 </div>
                    </div>
                    <div class="insurance-item">
                        <div class="m-title">医生服务</div>
                        <div class="pure-text mt-20">
                            正汉互联网医院是服务医患的互联网医疗平台，是医生和医生集团的互联网云端诊室，为医生提供线上接诊、患者管理、医生教育、电子处方及专业药品服务。</div>
                    </div>
                    <div class="insurance-item">
                        <div class="m-title">机构服务</div>
                        <div class="pure-text mt-20">
                            <p>面向药企：提供真实世界药品研究，通过互联网医院进行患者招募，提供数字营销赋能，方便患者管理。 </p>
                            <p>面向医院：实现线上智能处方、在线问诊、专科建设赋能。</p>
                        </div>
                    </div>
                </div>
                <div class="seek-medical">
                    <div class="b-title">正汉医生app - 医端服务</div>
                    <div class="advantage-wrap u-f">
                        <div><img src="../../assets/img/jiuyi/xxpt.png" class="advantage-icon">
                            <div class="m-title">学习平台</div>
                            <div class="pure-text mt-30">
                                汇聚国内外专科医疗机构，共同打造专科的线上学习平台，实时分享治疗理念，提高临床诊疗水平。 </div>
                        </div>
                        <div><img src="../../assets/img/jiuyi/hzgl.png" class="advantage-icon">
                            <div class="m-title">患者管理</div>
                            <div class="pure-text mt-30"> 建立互联网医患连接，实现患者分组管理，提升诊疗效率，打造医生口碑。 </div>
                        </div>
                    </div>
                    <div class="advantage-wrap u-f">
                        <div><img src="../../assets/img/jiuyi/ppys.png" class="advantage-icon">
                            <div class="m-title">品牌医生</div>
                            <div class="pure-text mt-30">
                                7大专科中心，统一技术标准、临床路径和诊疗规范，提升解决专科重大疾病的救治能力。帮助医生建立和提升个人品牌，提升门诊量和收入。 </div>
                        </div>
                        <div><img src="../../assets/img/jiuyi/ppxc.png" class="advantage-icon">
                            <div class="m-title">品牌宣传</div>
                            <div class="pure-text mt-30">
                                为符合条件的专科医生进行品牌宣传，通过内容传播、服务覆盖以及奖项评选、个人专访等形式，结合权威媒体，联合发布发声。让合作医生得到更广泛的认可、个人品牌价值得以放大。
                            </div>
                        </div>
                    </div>
                </div>
                <div class="green-wrap">
                    <div class="b-title-w">正汉互联网医院 - 患端服务</div>
                    <div class="advantage-wrap u-f">
                        <div><img src="../../assets/img/jiuyi/zxwz.png" class="advantage-icon">
                            <div class="m-title-w">在线问诊 快速连接</div>
                            <div class="pure-text-w mt-30"> 覆盖主任级专家，医生10分钟内快速响应，提供优质问诊服务 </div>
                        </div>
                        <div><img src="../../assets/img/jiuyi/zxgh.png" class="advantage-icon">
                            <div class="m-title-w">在线挂号 一键预约</div>
                            <div class="pure-text-w mt-30"> 节省到院排队时间，实现省时、 省心、省力 </div>
                        </div>
                        <div><img src="../../assets/img/jiuyi/plff.png" class="advantage-icon">
                            <div class="m-title-w">品类丰富 安全配送</div>
                            <div class="pure-text-w mt-30"> 专业药品库，全天候服务，统一专业的配送能力，购药安心 </div>
                        </div>
                    </div>
                    <div class="advantage-wrap u-f">
                        <div><img src="../../assets/img/jiuyi/viphy.png" class="advantage-icon">
                            <div class="m-title-w">VIP会员 畅享权益</div>
                            <div class="pure-text-w mt-30"> 会员专享10000+医药健康品超低购， 不限次图文问诊等超级权益 </div>
                        </div>
                        <div><img src="../../assets/img/jiuyi/kpzx.png" class="advantage-icon">
                            <div class="m-title-w">科普资讯 海量汇聚</div>
                            <div class="pure-text-w mt-30"> 超多健康科普，精准推送，随时随地获得想看内容 </div>
                        </div>
                        <div><img src="../../assets/img/jiuyi/vipfw.png" class="advantage-icon">
                            <div class="m-title-w">精准私域 VIP服务</div>
                            <div class="pure-text-w mt-30"> 专业社群私域服务，专家义诊、直播、 在群坐诊，大夫及时解答疑难，优惠福利会员制尊享
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="bottom-wrap u-f">
                    <div>
                        <div class="b-title">妙手医生微信小程序</div>
                        <div class="medication-title">专业医生团队为您提供健康咨询服务</div>
                        <div class="medication-title">提供同城快速送药上门服务</div>
                    </div>
                    <div class="doctot-img"><img data-src="../../assets/img/jiuyi/seekDoctor12.jpg"
                            src="../../assets/img/jiuyi/seekDoctor12.jpg" lazy="loaded"></div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import seo from "../../common/seo"
    export default {
        metaInfo: {
            ...seo.service
        },
        components: {},
        data() {
            return {

            }
        },
        mounted() {
            this.$root.Headerbgc = '#fff'
        }

    }
</script>

<style lang="scss" scoped>
    .index {
        .screen-wrap {
            width: 100%;
            background: #eee;
            padding-top: 120px;
            // padding-bottom: 60px;

            .m-title,
            .m-title-w {
                font-size: 24px;
                font-weight: 500;
                line-height: 33px;
            }


            .pure-text,
            .pure-text-w {
                font-size: 16px;
                font-weight: 400;
                text-align: justify;
            }

            .pure-text-w {
                color: #fff;
                line-height: 24px;
            }

            .mt-20 {
                margin-top: 20px;
            }

            .mt-30 {
                margin-top: 20px;
            }

            .b-title,
            .b-title-w {
                font-size: 36px;
                font-weight: 500;
            }

            .b-title {
                color: #000;
                line-height: 50px;
            }

            .b-title-w {
                color: #fff;
            }

            .advantage-wrap {
                justify-content: space-between;
            }

            .content-wrap {
                width: 1200px;
                margin: 0 auto;

                .top-wrap {
                    width: 100%;
                    position: relative;
                    height: 368px;

                    .insurance-banner {
                        width: 100%;
                        display: block;
                    }

                    .top-con {
                        position: absolute;
                        top: 104px;
                        right: 73px;
                        text-align: left;

                        .top-title {
                            font-size: 36px;
                            color: #333;
                            line-height: 50px;
                        }

                        .top-text {
                            font-size: 16px;
                            color: #777;
                            line-height: 30px;
                            margin-top: 20px;
                            width: 512px;
                        }
                    }
                }

                .insurance-wrap {
                    padding: 60px 70px;
                    background: #fff;
                    justify-content: space-around;
                    box-sizing: border-box;

                    .insurance-item {
                        width: 320px;

                        .m-title {
                            color: #000;
                        }

                        .pure-text {
                            color: #666;
                            line-height: 30px;
                            font-family: PingFangSC-Regular, PingFang SC;
                        }

                    }
                }

                .seek-medical {
                    width: 100%;
                    padding: 60px 72px;
                    background: #f8f8f8;
                    box-sizing: border-box;
                    text-align: center;

                    .b-title {
                        color: #000;
                        line-height: 50px;
                    }

                    .advantage-wrap {
                        margin-top: 34px;

                        .advantage-icon {
                            width: 72px;
                            margin-bottom: 30px;
                        }

                        .m-title {
                            color: #000;
                        }

                        .pure-text {
                            color: #666;
                            line-height: 30px;
                            font-family: PingFangSC-Regular, PingFang SC;
                        }

                        .pure-text {
                            width: 420px;
                            text-align: center;
                        }
                    }
                }

                .green-wrap {
                    background: #4C89FE;
                    padding: 60px 72px 57px;
                    box-sizing: border-box;
                    text-align: center;

                    .advantage-wrap {
                        margin-top: 59px;

                        .advantage-icon {
                            width: 72px;
                            margin-bottom: 30px;
                        }

                        .m-title-w {
                            color: #fff;
                        }

                        .pure-text-w {
                            width: 309px;
                            text-align: center;
                        }
                    }

                    .advantage-wrap:nth-child(2) {
                        margin-top: 39px;
                    }
                }

                .bottom-wrap {
                    width: 100%;
                    background: #fff;
                    justify-content: space-between;
                    align-items: center;
                    padding: 50px 98px 50px 72px;
                    box-sizing: border-box;
                    margin-bottom: 60px;

                    .b-title {
                        margin-bottom: 39px;
                    }

                    .b-title:after {
                        content: "";
                        display: block;
                        width: 60px;
                        height: 2px;
                        background: #00aa96;
                        margin-top: 9px;
                    }

                    .medication-title {
                        color: #777;
                        line-height: 30px;
                        font-size: 16px;
                        text-align: left;
                        margin-top: 0.16rem;

                    }

                    .medication-title:before {
                        content: "";
                        width: 12px;
                        height: 12px;
                        background: #00aa96;
                        margin-right: 16px;
                        display: inline-block;
                    }

                    .doctot-img {
                        width: 554px;
                        height: 479px;
                        line-height: 479px;
                        text-align: center;
                        margin: 0 auto;

                        img {
                            vertical-align: middle;
                            width: auto;
                            max-width: 100%;
                            display: inline-block;
                        }
                    }

                }


            }
        }
    }
</style>