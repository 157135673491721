<template>
  <div class="index">
    <div class="bgeee">
      <div class="same_width">
        <div class="bgfff">
          <div class="banner_height"><img src="../../assets/img/t_bannercjwt.png"></div>
          <h3>正汉互联网医院投诉建议</h3>
          <div class="content">
            <div class="top">
              亲爱的朋友：
            </div>
            <div class="duan">
              作为互联网健康平台，我们也许无法如您的家人那样100%理解您的困难，所以您的每一次建议及意见，对我们都非常重要！
            </div>
            <div class="duan">
              您认为我们向您提供的健康医疗信息是否足够？我们的产品以及服务是否能达到您的期望？我们的药品邮寄是否及时到达您的身边？我们对您提出的合理化要求的反应是否迅速？......
            </div>
            <div class="duan">
              我们愿用心倾听您的声音，把您的需求放在工作首位，对您的反馈迅速做出反应，采纳您的意见，并进行合理充分调查，进而改善我们的产品与服务，满足您以及广大用户朋友的需求！
            </div>
            <div class="bottom">
              投诉建议电话：18168938530
            </div>
            <div class="bottom">
              投诉建议邮箱：498694540@qq.com
            </div>
            <div class="bottom">
              投诉建议QQ：498694540
            </div>
            <div class="bottom">
              微信投诉建议：
            </div>
            <div class="erweima">
              <img src="../../assets/img/fk_code.png" alt="">
            </div>
          </div>




        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import seo from "../../common/seo"
  export default {
    metaInfo: {
      ...seo.join
    },
    components: {},
    data() {
      return {

      }
    },
    mounted() {
      this.$root.Headerbgc = '#fff'
    }

  }
</script>

<style lang="scss" scoped>
  .index {
    .bgeee {
      background: #eee;
      padding-top: 120px;

      .same_width {
        width: 1200px;
        margin: 0 auto;

        .bgfff {
          background: #fff;
          padding-bottom: 280px;
          text-align: left;

          .banner_height {
            width: 1200px;
            height: 368px;
            line-height: 368px;
            text-align: center;
            margin: 0 auto;

            img {
              width: auto;
              max-width: 100%;
              display: block;
            }
          }

          h3 {
            margin: 50px 0 30px 100px;
            font-size: 32px;
            color: #333;
          }

          .content {
            padding: 0 100px;
             font-size: 16px;
          }

          .top {
          }

          .duan {
            text-indent: 2em;
           
            margin-bottom: 20px;
          }
          .bottom{

          }
          .erweima{
            padding: 10px 0 0 100px;
            img{

            }
          }


        }
      }
    }
  }
</style>