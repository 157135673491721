<template>
    <div class="index">
        <div class="bgeee">
            <div class="same_width">
                <div class="bgfff">
                    <div class="banner_height"><img src="../../assets/img/image/join_banner.jpg"></div>
                    <h3>正汉互联网医院招聘</h3>
                    <!-- <p> 正汉互联网医院目前正在招聘商务、研发、运营等相关岗位，如有意向欢迎将简历发至 <span>498694540@qq.com</span></p>
                    <p>我们将尽快与您联系，期待您的加入。</p> -->
                    <div class="contents">

                        <div class="tabs_b u-f">
                            <div class="t_left">
                                <div class="t_item " :class="{avtives:tabindex==t.tId}" v-for="t,i in tabs" :key="i"
                                    @click="selecttab(t)">{{t.name}}</div>
                            </div>

                            <div class="t_right">
                                <div v-for="(w,index) in works.filter(a=>a.tId==tabindex)" :key="index">
                                    <div class="z_type">{{w.type}}</div>
                                    <div class="work_item" v-for="(w,ind) in w.child" :key="ind">
                                        <div class="work_tit">{{w.name}}</div>
                                        <div class="gw_b" v-if="w.duty.length>0">
                                            <div class="y_tit">岗位职责：</div>
                                            <div class="y_cons" v-for="z,i in w.duty" :key="i">{{i+1}}、{{z}}</div>
                                        </div>
                                        <div class="gw_b" v-if="w.qualification.length>0">
                                            <div class="y_tit">任职资格：</div>
                                            <div class="y_cons" v-for="q,i in w.qualification" :key="i">{{i+1}}、{{q}}
                                            </div>
                                        </div>
                                         <div class="gw_b" v-if="w.time&&w.time.length>0">
                                            <div class="y_tit">工作时间和地点：</div>
                                            <div class="y_cons" v-for="q,i in w.time" :key="i">{{i+1}}、{{q}}
                                            </div>
                                        </div>
                                         <div class="gw_b" v-if="w.other&&w.other.length>0">
                                            <!-- <div class="y_tit">工作时间和地点：</div> -->
                                            <div class="y_cons" v-for="q,i in w.other" :key="i">{{q}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="tabindex==3">
                                    <div class="lx_b">
                                        <div class="title_b">
                                            <div class="tit">
                                                申请方式
                                            </div>
                                        </div>
                                        <p>1、请将个人简历投递至：<span
                                                style="color:#0078ff;">kuaikang_1226@qq.com</span>，简历名称请按照“社会招聘+姓名+应聘岗位”进行书写，并附上个人证件照片（简历中含照片亦可）
                                        </p>
                                        <p>2、电话联系：18068260905 联系人：鲍女士</p>
                                    </div>
                                    <div class="lx_b">
                                        <div class="title_b">
                                            <div class="tit">
                                                工作地址
                                            </div>
                                        </div>
                                        <p>无锡滨湖区梁清路829号（靠近荣巷历史文化街，地铁2号线到荣巷站步行800米，公交112路/40路/608路/88路/26路都可到）</p>
                                    </div>
                                    <div class="lx_b">
                                        <div class="title_b">
                                            <div class="tit">
                                                公司福利
                                            </div>
                                        </div>
                                        <h2 style="font-weight:600;">平台大+晋升空间大+带薪年假+包食宿+节日福利卡+团建活动</h2>
                                        <p>1、<strong>伙食：</strong>免费提供优质工作餐，四菜一汤。</p>
                                        <p>2、<strong>住宿：</strong>提供花园式小区住宿条件，园区内配备有娱乐休闲区，宿舍配备公寓床、空调、衣柜、电脑桌、热水器。专职宿舍管理阿姨，保障安全，卫生。小区周边有医院、商场、大型超市、银行、餐饮娱乐设施、公共交通地铁等配套设施。
                                        </p>
                                        <p>3、<strong>保险：</strong>依法为员工购买社会保险。</p>
                                        <p>4、<strong>假期：</strong>享有国家法定带薪假：婚假、产假、丧假、病假，另工作满一年以上者可享有有薪年假。</p>
                                        <p>5、<strong>活动：</strong>公司每季度定期组织员工生日会、团建活动以及每月定期聚餐，员工表彰会及其它体育活动等。</p>
                                        <p>6、<strong>专业培训：</strong>入职均有系统化的培训，开班式培训，专业的带教老师以及授课讲师，让你能够快速掌握专业知识以及项目上的工作流程
                                        </p>
                                        <p>7、<strong>公平、透明、快速的晋升空间：</strong>初级专员→资深员工→组长→主管助理→主管→主任→总监，季度评估，一年内就有晋升机会；<strong>横向晋升：</strong>跨部门平级调岗
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import seo from "../../common/seo"
    import works from "../../common/works"
    export default {
        metaInfo: {
            ...seo.join
        },
        components: {},
        data() {
            return {
                works,
                tabs: [{
                        tId: 1,
                        name: '医学岗位'
                    },
                    {
                        tId: 2,
                        name: '专业岗位'
                    },
                    {
                        tId: 3,
                        name: '联系方式'
                    },
                ],
                tabindex: 1
            }
        },
        mounted() {
            this.$root.Headerbgc = '#fff'
        },
        methods: {
            selecttab(t) {
                this.tabindex = t.tId
            }
        }

    }
</script>

<style lang="scss" scoped>
    .index {
        .bgeee {
            background: #eee;
            padding-top: 120px;

            .same_width {
                width: 1200px;
                margin: 0 auto;

                .bgfff {
                    background: #fff;
                    padding-bottom: 280px;
                    text-align: left;

                    .banner_height {
                        width: 1200px;
                        height: 368px;
                        line-height: 368px;
                        text-align: center;
                        margin: 0 auto;

                        img {
                            width: auto;
                            max-width: 100%;
                            display: block;
                        }
                    }

                    h3 {
                        margin: 50px 0 30px 60px;
                        font-size: 32px;
                        color: #333;
                    }

                    .contents {
                        margin: 0 auto;
                        padding: 30px 60px 0px;
                        flex: 1;
                        overflow: auto;
                    }

                    .tabs_b .t_item {
                        font-size: 18px;
                        padding: 15px 0;
                        width: 300px;
                        cursor: pointer;
                        /* border: 1px solid red; */
                    }

                    .tabs_b .t_item.avtives {
                        padding-left: 20px;
                        box-sizing: border-box;
                        border-left: 4px solid #5997fa;
                        background-color: #F2F7FF;
                        font-weight: 600;
                        color: #5997fa;
                    }



                    .work_item:nth-child(3) {
                        padding-top: 0;
                    }

                    .z_type {
                        margin: 0 60px;
                        font-size: 22px;
                        display: inline-block;
                        position: relative;
                    }

                    .z_type::after {
                        content: '';
                        width: 100%;
                        height: 10px;
                        background-color: #5997fa;

                        float: left;
                        margin-top: -10px;
                    }

                    .work_item {
                        margin: 0 60px;
                        padding: 25px 0;
                        border-bottom: 1px solid rgb(241, 241, 241);

                        .work_tit {
                            font-size: 22px;
                            font-weight: 600;
                            // margin-bottom: 15px;
                            color: #000;
                        }

                        .gw_b {
                            margin-top: 15px;

                            .y_tit {
                                font-size: 16px;
                                font-weight: 600;
                                margin-bottom: 5px;
                            }

                            .y_cons {
                                font-size: 15px;
                                margin-bottom: 2px;
                                line-height: 1.8;
                            }
                        }
                    }

                    .lx_b {
                        margin: 0px 60px;

                        .title_b {
                            display: flex;

                            font-size: 22px;

                            .tit {
                                position: relative;
                            }

                            .tit::after {
                                content: '';
                                width: 100%;
                                height: 10px;
                                background-color: #5997fa;

                                float: left;
                                margin-top: -10px;
                            }

                            margin-bottom: 20px;
                        }

                        p {
                            font-size: 15px;
                            margin-bottom: 0px;
                            line-height: 1.8;
                        }
                    }

                }
            }
        }
    }
</style>