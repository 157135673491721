import {
  createStore
} from 'vuex'

// 引入持久化
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  namespaced: true,
  state: {
    flagPc: 1,
    Jump: 0,
    JumpPath:'',
  },
  //同步方法
  mutations: {
    UPDATEFLAGPC(state, data) {
      state.flagPc = data;
    },
    UPDATEJump(state, data) {
      state.Jump = data;
    },
    UPDATEJumpPath(state, data) {
      state.JumpPath = data;
    },
  },
  //异步方法
  actions: {},
  //计算属性
  getters: {},
  //模块
  modules: {},
  //持久化数据
  plugins: [

    // createPersistedState({

    //   key: 'vuex', // 存储是的名字

    //   // 也可以是sessionstorage

    //   storage: window.localStorage

    // })
  ]
})