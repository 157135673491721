<template>
    <div class="index">
        <div class="bgeee">
            <div class="same_width">
                <div class="bgfff">
                    <div class="tit_box">
                        <div class="title">
                            {{datas.name}}
                        </div>
                        <div class="l_tit">
                            时间：{{datas.create_time}}
                        </div>
                    </div>

                    <div style='white-space: normal;' class="contentBox" v-html="content"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import urlApi from '../../common/config'
    export default {
        metaInfo() {
            return {
                title: this.seoTitle,
                meta: [{
                        name: "keyWords",
                        content: this.seokeyWords,
                    },
                    {
                        name: "description",
                        content: this.seoDescription,
                    },
                ]
            }
        },
        data() {
            return {
                currentPage: 1,
                pageSize: 10,
                list: [],
                total: 0,
                content: '',
                datas: {},
                seoTitle: '正汉互联网医院一站式医疗健康生态系统，您的私人顾问！专家“云”集,在线问诊,在线咨询,网上预约挂号！',
                seokeyWords: '正汉,互联网医院,智能导诊,医疗软件, AI医疗, 人工智能, 预约挂号, 知识图谱, 医院系统, 医师辅助, 药店系统, 智能导诊,智能诊断,预问诊、智能问药,智能问答,健康咨询等服务',
                seoDescription: '正汉互联网医院致力打造一站式医疗健康生态系统，秉承着“专业、规范、创新、至善”的理念，为广大患者提供便捷、完善的互联网+医疗健康服务。 凭借“互联网+医疗健康”信息服务体系的支撑，正汉互联网医院扎实推进医疗资源整合，与专家建立协作关系，精准对接、满足群众多层次多样化的健康需求。',
            }
        },
        created() {
            this.getContent()
        },
        mounted() {
            this.$root.Headerbgc = '#fff'
        },
        methods: {
            handleSizeChange(val) {},
            handleCurrentChange(val) {},
            getContent() {
                let params = {
                    article_type: 2,
                    id: this.$route.query.id,
                }
                this.axios.post(urlApi.articleApi+'/admin/wss/health/Mread', params).then(res => {
                    let data = res.data.data
                    this.datas = data
                    let content = data.content
                     ? data.content.replace(/\<img/gi,
                        '<img class="richimg"') : ''
                    this.content = content ? content.replace(/\white-space: pre/gi,
                        'white-space: normal') : ''

                    this.seoTitle = data.name
                    this.seokeyWords = data.keyword
                    this.seoDescription = data.describe
                })
            },
        }

    }
</script>

<style lang="scss" scoped>
    .index {
        .bgeee {
            background: #eee;
            padding-top: 110px;

            .same_width {
                width: 1000px;
                margin: 0 auto;

                .bgfff {
                    background: #fff;
                    // padding-bottom: 100px;
                    text-align: left;
                    overflow: hidden;
                    border-radius: 4px;

                    .tit_box {
                        border-bottom: 1px dashed rgb(224, 224, 224);
                        margin-bottom: 20px;
                        padding: 20px 0;

                        .title {
                            padding: 0 24px;
                            font-size: 26px;
                            word-wrap: break-word;
                            color: #6696FF;
                            font-weight: 600;
                            // margin: 10px 0;
                            word-break: break-all;
                            text-align: center;
                            margin-bottom: 10px;
                        }

                        .l_tit {
                            text-align: center;
                        }
                    }

                    .contentBox {
                        padding: 0 24px 16px;
                        background: #fff;
                        border-radius: 2px;
                    }



                }
            }
        }
    }
</style>