<template>
  <div class="index">
    <div class="about bgeee">
      <div class="same_width">
        <div class="tw_wp" v-for="(item,index) in list" :key="index">
          <div class=" tab_all" @click="gocontent(item)">
            <div class="u-f u-jsb u-ac u-mar">
              <div class="n_left">
                <div class="n_title van-multi-ellipsis--l2">
                  {{ item.name }} </div>
                <div class="n_text">
                  <p v-html="item.content"></p>
                </div>
                <div class="u-f u-ac u-jsb av_wrap n_padd">
                  <div class="u-f u-ac">
                    <!-- <div class="img_box">
                        <img :src="item.author_avatar" alt="" class="avatar_img">
                      </div> -->
                    <!-- <span class="avatar_name ava_n_box">{{item.author_text}}<span style="color:#6d6d6d"
                          v-if="item.user&&item.user.doctorAuditName!='正汉小编'">·{{item.user.doctorAduitComptent}}</span></span> -->
                  </div>
                  <div class="price_b" v-if="item.price>0">￥{{item.price>0?item.price:''}}
                  </div>
                </div>
              </div>
              <div v-if="item.file.length>0" class="r_imgs" :style="'background:url('+item.file[0].url+')'">
              </div>
            </div>
          </div>
        </div>
        <div>
          <van-pagination v-model="currentPage" :items-per-page="pagesize" :total-items='total' mode="simple"
            @change='pagechange' />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from "../../components/pc/home/header.vue";
  import Footer from "../../components/pc/home/footer.vue";
  import seo from "../../common/seo"
  import urlApi from '../../common/config'
  export default {
    metaInfo: {
      ...seo.news
    },
    components: {
      Header,
      Footer
    },
    data() {
      return {
        list: [],
        total: 0,
        currentPage: 1,
        pagesize: 10
      }
    },
    created() {
      this.getlist()
    },
    mounted() {
      this.$root.Headerbgc = '#fff'
      document.dispatchEvent(new Event('render-event'))
    },
    methods: {
      pagechange(a) {
        this.currentPage = a
        this.getlist()
      },
      getlist() {
        let params = {
          article_type: 2,
          page: this.currentPage,
          page_count: this.pagesize
        }
        this.axios.post(urlApi.articleApi+'/admin/wss/health/Mlist', params).then(res => {
          this.list = res.data.data.list
          this.total = res.data.data.totalCount
        })
      },
      gocontent(n) {
        this.$router.push({
          path: '/mNewContent',
          query: {
            id: n.id
          }
        })
      }
    }

  }
</script>

<style lang="scss" scoped>
  .index {
    .bgeee {
      background: #eee;

      .same_width {
        width: 100%;
        margin: 0 auto;
        background: #fff;
        padding: 20px 10px 20px;
        box-sizing: border-box;



        .r_imgs {
          margin-left: 10px;
          width: 135px;
          height: 105px;
          border-radius: 5px;
          display: block;
          background-size: cover !important;
          background-position: center !important;
          background-repeat: no-repeat !important;
        }


        .tw_wp {
          padding: 10px 0 10px;
          border-bottom: 1px solid #f8f9ff;
        }

        .n_left {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          // width: 60%;
          flex: 1;

          .n_title {
            // display: block;
            font-size: 16px;
            // border: 1px solid red;
            margin-bottom: 15px;
            font-weight: bold;
          }

        }

        .n_text {
          width: 100%;
          // height: 50px;
          font-size: 12px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #999;
        }

        .rightliul {
          span {
            margin-right: 3px;
          }
        }

        .gzbtn {
          font-size: 12px;
          // padding: 1px 5px;
          border-radius: 2px;

          img {
            width: 18px;
          }
        }

      }
    }
  }
</style>