<template>
    <div class='index'>
        <div class="footer_box">
            <div class="qrcode_follow">
                <div class="follow_weixin">
                    <div class="banner_wrap p_top_footer"><img data-src="../../assets/img/gzh.png"
                            src="../../assets/img/gzh.png" lazy="loaded"></div>
                    <span>扫一扫<br>关注微信公众号</span>
                </div>
                <div class="follow_weixin">
                    <div class="banner_wrap p_top_footer"><img data-src="../../assets/img/index.jpg"
                            src="../../assets/img/index.jpg" lazy="loaded"></div>
                    <span>扫一扫<br>打开正汉小程序</span>
                </div>
            </div>
            <div class="footer_infos"><img src="../../assets/img/zhlogo.png" alt=""
                    class="footer_logo"><br>江苏省无锡市滨湖区隐秀路813号A座7楼<br>
                Copyright&nbsp;©&nbsp;2022-2023&nbsp;无锡正汉互联网医院有限公司版权所有<br><a style="color:#999;"
                    href="https://beian.miit.gov.cn/" target="_blank">备案号：苏ICP备2022012576号-1</a><br>
                <div class="gnbn">
                    <img src="../../assets/img/Registration_icon.png" alt=""><a style="color:#999;"
                        href="https://beian.mps.gov.cn/#/query/webSearch?code=32021102002429" rel="noreferrer"
                        target="_blank">苏公网安备32021102002429号</a></div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {};
        },
        watch: {},
        computed: {},
        created() {},
        mounted() {},
        methods: {},
    };
</script>
<style lang="scss" scoped>
    .index {
        .footer_box {
            padding: 1rem 0 0.84rem;
            text-align: center;

            .qrcode_follow {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-justify-content: space-around;
                -ms-flex-pack: distribute;
                justify-content: space-around;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                margin: 0 0.75rem 0.8rem 0.75rem;

                .follow_weixin {
                    .banner_wrap.p_top_footer {
                        width: 1.8rem;
                        height: 1.8rem;
                        padding: 0;
                        margin: 0 auto;
                    }

                    .banner_wrap {
                        padding-top: 48.974%;
                        position: relative;
                        overflow: hidden;

                        img {
                            position: absolute;
                            width: 100%;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                        }
                    }
                }

                span {
                    display: block;
                    line-height: .36rem;
                    font-size: .24rem;
                    color: #666;
                }
            }

            .footer_infos {
                line-height: .32rem;
                font-size: .22rem;
                color: #999;

                .footer_logo {
                    width: 1.9rem;
                    margin: 0 0 0.24rem;
                }

                .gnbn {
                    img {
                        width: 16px;
                        margin-right: 3px;
                    }

                   

                }
            }
        }
    }
</style>