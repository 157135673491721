<template>
	<div id="goTop">
		<transition name="van-slide-right">
			<div class="goTop" v-show="goTopShow" @click="goTop">
				<van-icon name="back-top" class="b_t_icon"/>
			</div> 
		</transition>
	</div>
</template>
<script>
	export default {
		name: "goTop",
		data() {
			return {
				scrollTop: "",
				goTopShow: false,
			};
		},
		methods: {
			handleScroll() {
				this.scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (this.scrollTop > 200) {
					this.goTopShow = true;
				} else {
					this.goTopShow = false;
				}
			},
			goTop() {
				let timer = null,
					_that = this;
				cancelAnimationFrame(timer);
				timer = requestAnimationFrame(function fn() {
					if (_that.scrollTop>600) {
						_that.scrollTop=600
					}
					if (_that.scrollTop > 0) {
						_that.scrollTop -= 100;
						document.body.scrollTop = document.documentElement.scrollTop =
							_that.scrollTop;
						timer = requestAnimationFrame(fn);
					} else {
						cancelAnimationFrame(timer);
						_that.goTopShow = false;
					}
				});
			},
		},
		mounted() {
			window.addEventListener("scroll", this.handleScroll);
		},
		destroyed() {
			window.removeEventListener("scroll", this.handleScroll);
		},
	};
</script>

<style scoped lang='scss'>
	.goTop {
		position: fixed;
		right: 10px;
		bottom: 100px;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff;
		box-shadow: 0 0 5px 0 rgb(216, 216, 216);

		.b_t_icon {
			font-size: 38px;
		}
	}
</style>