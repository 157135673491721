<template>

  <div v-if="route.meta.h!=0">
    <div v-if="flagPc">
      <Header :bgccolor="Headerbgc" />
    </div>
    <div v-else>
      <mHeader :top='hTop' />
    </div>
  </div>
  <!-- 主体 -->
  <router-view v-slot='{Component}'>
    <keep-alive>
      <component :is="Component" v-if="KeepAlive" v-touch:swipedown="swipedown" v-touch:swipeup="swipeup">
      </component>
    </keep-alive>
    <component :is="Component" v-if="!KeepAlive" v-touch:swipedown="swipedown" v-touch:swipeup="swipeup">
    </component>
  </router-view>
  <div v-if="route.meta.f!=0">
    <div v-if="flagPc">
      <Kefu />
      <el-backtop :right="135" :bottom="35" />
      <Footer />
    </div>
    <div v-else>
      <mFooter />
      <backTop />
      <KF v-if="kfshow" :img="iconsurl" @gopage='gokf' />
    </div>
  </div>
</template>
<script>
  import Header from "./components/pc/home/header.vue";
  import mHeader from "./components/Mobile/header.vue";
  import Footer from "./components/pc/home/footer.vue";
  import mFooter from "./components/Mobile/footer.vue";
  import Kefu from "./components/pc/kefu/index.vue";
  import backTop from "./components/Mobile/backTop.vue";
  import KF from "./components/Mobile/drag/index.vue";
  import {
    computed,
    defineComponent,
    onMounted,
    onUpdated,
    reactive,
    ref,
    toRefs,
    toRaw,
    watch

  } from "vue";
  import {
    useRouter
  } from 'vue-router';
  import {
    useStore
  } from "vuex";
  export default defineComponent({
    name: "App",
    components: {
      Kefu,
      Header,
      Footer,
      mHeader,
      mFooter,
      backTop,
      KF,
    },
    created() {


    },
    mounted() {
      document.dispatchEvent(new Event('render-event'))
    },
    setup(props, ctx) {
      let {
        state,
        commit
      } = useStore();
      let flagPc = computed(() => state.flagPc)
      let r = useRouter()
      let router = toRaw(r)
      // console.log(toRaw(useRouter()).currentRoute);
      let route = toRaw(r).currentRoute.value
      const data = reactive({
        flagPc: true,
        Headerbgc: '',
        hTop: '0',
        iconsurl: require('./assets/img/phone/kf_icon2.png'),
        kfshow: computed(() => {
          let r = router.currentRoute.value.path.split('/')[1]
          return r != 'mKf' && r != 'Loading'
        }),
        KeepAlive: false
      })
      onMounted(() => {});
      onUpdated(() => {

      })
      watch(
        () => router.currentRoute.value.meta,
        (newValue) => {
          data.KeepAlive = newValue.KeepAlive
        }, {
          immediate: true,
          deep: true
        }
      )


      IsPC()

      function swipedown() {
        // console.log('下滑');
        data.hTop = '0'
      }

      function swipeup() {
        // console.log('上滑');
        data.hTop = '-1rem'
      }


      function IsPC() {
        let userAgentInfo = navigator.userAgent;
        let Agents = [
          'Android',
          'iPhone',
          'SymbianOS',
          'Windows Phone',
          'iPad',
          'iPod'
        ];
        for (let i = 0; i < Agents.length; i++) {
          if (userAgentInfo.indexOf(Agents[i]) > 0) {
            commit('UPDATEFLAGPC', 0)
            break;
          }
        }
        let f = (!JSON.parse(sessionStorage.getItem('flagPc'))&&JSON.parse(sessionStorage.getItem('flagPc'))!=0)?1:JSON.parse(sessionStorage.getItem('flagPc'))
        if (!sessionStorage.getItem('flagPc')) {
          sessionStorage.setItem('flagPc', 1)
          console.log(1);
        }
        if (sessionStorage.getItem('flagPc')) {
          if (f == flagPc.value) {
            if (flagPc.value) {

            } else {
              let html = document.querySelector('html')
              MobileFontSize()
              html.style.maxWidth = '750px'
              html.style.margin = '0 auto'
            }
          } else {
            sessionStorage.setItem('flagPc', flagPc.value)
            if (flagPc.value) {
              console.log('PC端');
              // router.replace('/')
              commit('UPDATEJump', 1)
              commit('UPDATEJumpPath', '/')
            } else {
              console.log('移动端1');
              // router.replace('/mHome')
              commit('UPDATEJump', 1)
              commit('UPDATEJumpPath', '/mHome')
              window.addEventListener("scroll", handleScroll);
              let html = document.querySelector('html')
              MobileFontSize()
              html.style.maxWidth = '750px'
              html.style.margin = '0 auto'
            }
          }
        } else {
          sessionStorage.setItem('flagPc', flagPc.value)
          if (flagPc.value) {
            console.log('第一次进');
            console.log('PC端');
            // router.replace('/')
            commit('UPDATEJump', 1)
            commit('UPDATEJumpPath', '/')
          } else {
            console.log('移动端2');
            // router.replace('/mHome')
            commit('UPDATEJump', 1)
            commit('UPDATEJumpPath', '/mHome')
            window.addEventListener("scroll", handleScroll);
            let html = document.querySelector('html')
            MobileFontSize()
            html.style.maxWidth = '750px'
            html.style.margin = '0 auto'
          }
        }
      }

      function MobileFontSize() {
        var docEl = document.documentElement,
          resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
          recalc = function () {
            var clientWidth = docEl.clientWidth;
            if (!clientWidth) return;
            let f = 50 * (clientWidth / 375);
            if (f < 100) {
              docEl.style.fontSize = 50 * (clientWidth / 375) + 'px';
            } else {
              docEl.style.fontSize = '100px'
            }
          };
        if (!document.addEventListener) return;
        window.addEventListener(resizeEvt, recalc, false);
        document.addEventListener('DOMContentLoaded', recalc, false);
      }

      function handleScroll() {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        if (scrollTop > 1) {} else {
          data.hTop = '0'
        }
      }

      function gokf() {
        router.push('/mKf')
      }

      return {
        ...toRefs(data),
        flagPc,
        route,
        router,
        swipedown,
        swipeup,
        IsPC,
        MobileFontSize,
        handleScroll,
        gokf
      }
    }
  });
</script>
<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: #2c3e50;
  }

  html {}

  li,
  ol,
  ul {
    list-style: none
  }

  a {
    color: #333;
    outline: none;
    text-decoration: none;
    background: none
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
    line-height: inherit
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  .u-f {
    display: flex;
  }

  .u-ac {
    align-items: center;
  }

  .u-aend {
    align-items: flex-end;
  }

  .u-ajc {
    justify-content: center;
  }

  .u-jsb {
    justify-content: space-between;
  }

  .u-jsbs {
    justify-content: space-between;
    flex-direction: column;
  }

  .u-ajar {
    justify-content: space-around;
  }

  .u-wrap {
    flex-wrap: wrap;
  }

  .u-cl {
    flex-direction: column;
  }

  .u-res {
    flex-direction: row-reverse;
  }

  .u-fd {
    flex-direction: column;
  }

  .u-grow {
    flex-grow: 1;
  }

  .u-flex1 {
    flex: 1;
  }
</style>