<template>
    <div class="index">
        <div class="bgeee">
            <div class="same_width">
                <div class="ks_b">
                    <div class="ks_xz_tit">选择科室</div>
                    <div class="ks_l_box">
                        <div class='t_it' @click="selecttypes(t,index)" :class="{t_it_ac:active1==index}"
                            v-for="(t,index) in types" :key="index">{{t.name}}</div>
                    </div>
                    <div class="t_child_b">
                        <div class='t_it2' @click="selecttypes2(t,index)" :class="{t_it_ac:active2==index}"
                            v-for="(t,index) in types_child" :key="index">{{t.name}}</div>
                    </div>
                </div>
                <div class="doc_b">
                    <el-skeleton :rows="35" animated v-if="isshowfirst" />
                        <el-empty description="暂无匹配医生" v-if="docFilterOptions.length==0"></el-empty>

                    <div class="ks_list_b" v-loading='docloading'>
                        <div class="ys_item " v-for="(it,index) in docFilterOptions" :key="index">
                            <!-- <div class="imgs"> -->
                            <el-image style="width: 80px; height: 80px" class="imgs" :src="it.avatar"
                                fit="cover" />
                            <!-- </div> -->
                            <div class="d_info">
                                <div class="d_name">{{it.name}}</div>
                                <div class="d_zc"></div>
                            </div>
                            <div class="d_yy">{{it.departName}} {{it.dictionaryName}}</div>
                            <div class="d_sc_b">
                                <div class="d_sc">擅长：{{it.beGoodAt}}</div>

                            </div>
                            <div class="xf_box" @click="showewm(it)">
                                <div class="sj"></div>
                                <div class="kuai">
                                    在线<br>复诊
                                </div>
                            </div>
                            <div class="fz_ewm_b" :class="{fz_e_ac:actives_id.indexOf(it.id)!=-1}">
                                <img src="../../assets/img/gzh.png" alt="">
                                您可使用微信扫描二维码关注公众号联系医生
                            </div>
                        </div>
                    </div>

                    <!-- <div class="page-box">
                        <el-pagination v-model:currentPage="currentPage" v-model:page-size="pageSize" background
                            @size-change="handleSizeChange" @current-change="handleCurrentChange"
                            layout="prev, pager, next,jumper" :total="count" />
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import seo from "../../common/seo"
    import doctors from "../../common/doctors"
    import urlApi from '../../common/config'
    export default {
        metaInfo: {
            ...seo.team
        },
        components: {

        },
        data() {
            return {
                types: [{
                        text: '内科',
                        id: 1,
                        pid: 0,
                        child: []
                    },
                    {
                        text: '外科',
                        id: 2,
                        pid: 0,
                        child: [{
                                text: '泌尿外科',
                                id: 3,
                                pid: 2,
                            },
                            {
                                text: '普通外科',
                                id: 4,
                                pid: 2,
                            },
                        ]
                    },
                    {
                        text: '妇科',
                        id: 5,
                        pid: 0,
                        child: [{
                                text: '妇科专业',
                                id: 6,
                                pid: 3,
                            },
                            {
                                text: '生殖健康',
                                id: 7,
                                pid: 3,
                            },
                            {
                                text: '不孕症专业',
                                id: 8,
                                pid: 3,
                            },

                        ]
                    },
                    {
                        text: '皮肤科',
                        id: 9,
                        pid: 0,
                        child: [{
                            text: '皮肤病专业',
                            id: 10,
                            pid: 9,
                        }, ]
                    },
                    {
                        text: '肿瘤科',
                        id: 11,
                        pid: 0,
                        child: []
                    },
                    {
                        text: '康复医学科',
                        id: 12,
                        pid: 0,
                        child: []
                    },
                    {
                        text: '中医科',
                        id: 14,
                        pid: 0,
                        child: []
                    },
                    {
                        text: '精神科',
                        id: 15,
                        pid: 0,
                        child: []
                    },

                ],
                active1: -1,
                types_child: [],
                active2: -1,
                doc_options: [],
                docloading: false,

                currentPage: 1,
                pageSize: 12,
                count: 0,
                isshowfirst: false,
                actives_id: [],
                docFilterOptions: []

            }
        },
        mounted() {
            this.isshowfirst = true
            this.$root.Headerbgc = '#fff'
            this.getdoc()
            this.getdepartment()
            this.getdoclist()
        },
        methods: {
            getdepartment() {
                let params = {}
                this.axios.get(urlApi.nodeApi+'/pc/getDepartmant', params).then(res => {
                    this.types = res.data.data.data.filter(a=>a.id!=6&&a.id!=372)
                })
            },
            getdoclist() {
                this.docloading = true

                this.axios.get(urlApi.nodeApi+`/pc/getDoctor`, {}).then(res => {
                    this.docloading = false
                    this.isshowfirst = false
                    this.doc_options = res.data.data.data
                    this.docFilterOptions = res.data.data.data
                })
            },

            selecttypes(t, i) {
                if (this.active1 != i) {
                    this.active1 = i
                    this.active2 = -1
                    this.types_child = t.children
                    this.handleDocList(t.id, t.pid)
                } else {
                    this.active1 = -1
                    this.active2 = -1
                    this.types_child = []
                    this.getdoclist()
                }

            },
            selecttypes2(t, i) {
                if (this.active2 != i) {
                    this.active2 = i
                    this.handleDocList(t.id, t.pid)
                } else {
                    this.active2 = -1
                    this.handleDocList(t.pid, 0)
                }

            },
            handleDocList(id, pid) {
                let doc_op = JSON.parse(JSON.stringify(this.doc_options))
                this.docFilterOptions = []
                let doc = []
                if (pid == 0) {
                    let t_c = JSON.parse(JSON.stringify(this.types_child))
                    if (t_c.length > 0) {
                        t_c.forEach(ele => {
                            doc_op.forEach(e => {
                                if (ele.id == e.depart) {
                                    doc.push(e)
                                }
                            })
                        })
                    } else {
                        doc_op.forEach(e => {
                            if (id == e.depart) {
                                doc.push(e)
                            }
                        })
                    }
                } else {
                    doc_op.forEach(e => {
                        if (id == e.depart) {
                            doc.push(e)
                        }
                    })
                }
                this.docFilterOptions = doc
            },
            getdoc() {


            },
            handleSizeChange(val) {
                console.log(`${val} items per page`)

            },
            handleCurrentChange(val) {
                console.log(`current page: ${val}`)
                this.currentPage = val
                this.actives_id = []
                this.getdoclist()
            },
            showewm(it) {
                let index = this.actives_id.indexOf(it.id)
                if (index != -1) {
                    this.actives_id.splice(index, 1)
                } else {
                    this.actives_id.push(it.id)
                }
            }
        }

    }
</script>

<style lang="scss" scoped>
    .index {
        .bgeee {
            background: #eee;
            padding-top: 120px;

            .same_width {
                width: 1200px;
                margin: 0 auto;

                .ks_b {
                    background-color: #fff;
                    padding: 5px 20px;

                    .ks_xz_tit {
                        font-size: 20px;
                        font-weight: 600;
                        padding: 10px 0;
                    }

                    .ks_l_box {
                        display: flex;
                        width: 100%;
                        flex-wrap: wrap;

                        .t_it {
                            font-size: 16px;
                            margin-right: 20px;
                            margin-bottom: 10px;
                            padding: 0 12px;
                            border-radius: 20px;
                            cursor: pointer;
                        }

                        .t_it_ac {
                            background-color: #0D3E8F;
                            color: #fff;
                        }
                    }

                    .t_child_b {
                        padding: 10px 0;
                        display: flex;
                        width: 100%;
                        flex-wrap: wrap;
                        border-top: 1px solid rgb(218, 218, 218);

                        .t_it2 {
                            font-size: 16px;
                            margin-right: 20px;
                            margin-bottom: 5px;
                            padding: 0 12px;
                            border-radius: 20px;
                            cursor: pointer;
                        }

                        .t_it_ac {
                            background-color: #0D3E8F;
                            color: #fff;
                        }
                    }
                }

                .doc_b {
                    padding: 10px;
                    background-color: #fff;
                    margin-top: 20px;

                    .ks_list_b {
                        display: flex;
                        // justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;
                        width: 100%;

                        .ys_item {
                            width: 270px;
                            padding: 0 5px;
                            box-sizing: border-box;
                            background-color: #fff;
                            border: 2px solid #d1d1d1;
                            // height: 300px;
                            border-radius: 2px;
                            position: relative;
                            margin-bottom: 25px;
                            margin-right: 10px;


                            .imgs {
                                // width: 40%;
                                margin: 15px auto;
                                border-radius: 50%;
                                overflow: hidden;
                                display: block;

                                img {
                                    width: 100%;
                                    display: block;
                                }
                            }

                            .d_info {
                                display: flex;
                                align-items: flex-end;
                                justify-content: center;
                                line-height: 1;

                                .d_name {
                                    font-size: 18px;
                                    font-weight: 600;
                                }

                                .d_zc {
                                    margin-left: 10px;
                                    color: #7a7a7a;
                                }
                            }

                            .d_yy {
                                text-align: center;
                                margin: 8px 0;
                                color: #000;
                            }

                            .d_sc_b {
                                height: 100px;
                            }

                            .d_sc {
                                border-top: 1px solid #d1d1d1;
                                padding-top: 10px;
                                color: #7a7a7a;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                width: 100%;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 3;
                                word-break: break-all;
                            }

                            .xf_box {
                                position: absolute;
                                top: 33px;
                                right: -11px;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                                cursor: pointer;
                                z-index: 3;

                                .sj {
                                    border: 5px solid #165B95;
                                    border-top-color: transparent;
                                    border-right-color: transparent;
                                    width: 0px;
                                    height: 0px;
                                    border-top-left-radius: 3px;
                                }

                                .kuai {
                                    background-color: #6EB9FA;
                                    border-top-left-radius: 5px;
                                    border-bottom-left-radius: 5px;
                                    border-bottom-right-radius: 2px;
                                    padding: 5px 10px;
                                    color: #fff;
                                    line-height: 1.2;

                                }
                            }

                            .fz_ewm_b {
                                position: absolute;
                                top: 0px;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;
                                background-color: #fff;
                                z-index: 2;
                                opacity: 0;
                                transform: scale(0);
                                transition: all 0.3s;



                                img {
                                    width: 80%;
                                    display: block;
                                }
                            }

                            .fz_e_ac {
                                opacity: 1;
                                transform: scale(1);
                                transition: all 0.3s;

                            }


                        }
                    }

                    .page-box {
                        display: flex;
                        justify-content: center;
                        padding: 15px 0;
                    }
                }
            }
        }
    }
</style>