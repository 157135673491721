<template>
  <div class="index">
    <van-nav-bar :title="datas.name" left-arrow :fixed="true" :placeholder="true"
      @click-left="prev=> this.$router.go(-1)">
    </van-nav-bar>
    <div class="about bgeee">
      <div class="same_width">
        <div class="bgfff">
          <div class="title">
            {{datas.name}}
          </div>
          <div class="contentBox" v-html="content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Header from "../../components/pc/home/header.vue";
  import Footer from "../../components/pc/home/footer.vue";
  import seo from "../../common/seo"
  import urlApi from '../../common/config'
  export default {
    metaInfo() {
      return {
        title: this.seoTitle,
        meta: [{
            name: "keyWords",
            content: this.seokeyWords,
          },
          {
            name: "description",
            content: this.seoDescription,
          },
        ]
      }
    },
    components: {
      Header,
      Footer
    },
    data() {
      return {
        datas: {},
        content: '',
        seoTitle: '正汉互联网医院一站式医疗健康生态系统，您的私人顾问！专家“云”集,在线问诊,在线咨询,网上预约挂号！',
        seokeyWords: '正汉,互联网医院,智能导诊,医疗软件, AI医疗, 人工智能, 预约挂号, 知识图谱, 医院系统, 医师辅助, 药店系统, 智能导诊,智能诊断,预问诊、智能问药,智能问答,健康咨询等服务',
        seoDescription: '正汉互联网医院致力打造一站式医疗健康生态系统，秉承着“专业、规范、创新、至善”的理念，为广大患者提供便捷、完善的互联网+医疗健康服务。 凭借“互联网+医疗健康”信息服务体系的支撑，正汉互联网医院扎实推进医疗资源整合，与专家建立协作关系，精准对接、满足群众多层次多样化的健康需求。',
      }
    },
    created() {
      this.getContent()
    },
    mounted() {
      this.$root.Headerbgc = '#fff'
      document.dispatchEvent(new Event('render-event'))
    },
    methods: {
      getContent() {
        let params = {
          article_type: 2,
          id: this.$route.query.id,
        }
        this.axios.post(urlApi.articleApi+'/admin/wss/health/Mread', params).then(res => {
          let data = res.data.data
          this.datas = data
          this.seoTitle = data.name
          this.seokeyWords = data.keyword
          this.seoDescription = data.describe
          let content = data.content ? data.content.replace(/\<img/gi,
            '<img class="richimg"') : ''
          this.content = content ? content.replace(/\white-space: pre/gi,
            'white-space: normal') : ''

        })
      },
    }

  }
</script>

<style lang="scss" scoped>
  .index {
    .bgeee {
      background: #eee;

      .same_width {
        width: 100%;
        margin: 0 auto;
        display: flex;

        .bgfff {
          background: #fff;
          text-align: left;
          width: 100%;

          .contentBox {
            padding: 0 10px 16px;
            background: #fff;
            border-radius: 2px;
            font-size:16px;
          }

          .title {
            padding: 0 10px;
            font-size: 20px;
            word-wrap: break-word;
            color: #222226;
            font-weight: 600;
            margin: 10px 0;
            word-break: break-all;
          }
        }
      }
    }
  }
</style>
<style>
  .richimg {
    max-width: 100% !important;
    height: auto !important;
  }
</style>