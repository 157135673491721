<template>
    <div class="index">
        <div class="bgeee">
            <div class="same_width">
                <div class="bgfff">
                    <div class="banner_height"><img src="../../assets/img/phone/jyznpage.png"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import seo from "../../common/seo"
    export default {
        metaInfo: {
            ...seo.guide
        },
        components: {},
        data() {
            return {

            }
        },
        mounted() {
            this.$root.Headerbgc = '#fff'
        }

    }
</script>

<style lang="scss" scoped>
    .index {
        .bgeee {
            background: #eee;

            .same_width {
                width: 100%;
                margin: 0 auto;

                .bgfff {
                    background: #fff;
                    // padding-bottom: 280px;
                    text-align: left;

                    .banner_height {
                        width: 100%;
                        text-align: center;
                        margin: 0 auto;

                        img {
                            width: auto;
                            max-width: 100%;
                            display: block;
                        }
                    }


                }
            }
        }
    }
</style>