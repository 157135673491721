<template>
    <div class="index">
        <div class="bgeee">
            <div class="same_width">
                <div class="banner_height"><img src="../../assets/img/t_bannercjwt.png"></div>
                <div class="bgfff">
                    <div class="q_item" v-for="(q,index) in questions" :key="index">
                        <div class="q">
                            <div class="dian"></div>Q:{{q.q}}
                        </div>
                        <div class="a">A:{{q.a}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import seo from "../../common/seo"
    export default {
        metaInfo: {
            ...seo.problem
        },
        components: {},
        data() {
            return {
                questions: [{
                        id: 1,
                        q: '如何支付就诊咨询费？',
                        a: '您可以在提交预约后的支付诊金流程进行支付，支付方式可以选择支付宝，银联支付等的方式。'
                    },
                    {
                        id: 2,
                        q: '如何改签我的就诊时间？',
                        a: '您可以在手机微信，公众号“正汉互联网医院”登录之后，进入就诊列表，点击右上角的改/退按钮，选择改签进入改签页面。选择您需要改签的就诊，联系您的专属助理进行改签。'
                    },
                    {
                        id: 3,
                        q: '服务不满意怎么办？',
                        a: '若出现服务不满意可申请退款，具体退款说明可参照服务指南中的退款须知。您也可以联系为您服务的助理医生进行处理。'
                    },
                    {
                        id: 4,
                        q: '助理医生能做什么？',
                        a: '助理是您在正汉互联网医院就医的的私人助理，免费为您服务，在您预约即就诊过程中，遇到任何问题，都可以与助理取得联系，助理会帮助您解决困难。'
                    },
                    {
                        id: 5,
                        q: '在疾病里找不到对应的科室？',
                        a: '您所搜索的疾病名称和我们平台上的疾病名称有个别文字的差异，您可以立即咨询在线助理，询问具体情况。'
                    },
                    {
                        id: 6,
                        q: '如何预约医生？',
                        a: '在医院网站上点击立即预约，或者通过手机微信公众号“正汉互联网医院”首页点击挂号，按照指引即可一步步完成。'
                    },
                    {
                        id: 7,
                        q: '如何上传我的病历资料？',
                        a: '当您处于等待就诊的流程时，在公众号“正汉互联网医院”点击资料管理按钮后，进入资料上传页面，选择拍摄或相册里的照片进行上传，具体拍摄要求在上传页面内点击上传须知查看。'
                    },

                ]
            }
        },
        mounted() {
            this.$root.Headerbgc = '#fff'
        }

    }
</script>

<style lang="scss" scoped>
    .index {
        .bgeee {
            background: #eee;

            .same_width {
                width: 100%;
                margin: 0 auto;

                .banner_height {
                    width: 100%;

                    img {
                        width: 100%;
                        display: block;
                    }
                }

                .bgfff {
                    background: #fff;
                    text-align: left;
                    overflow: hidden;
                    padding: 10px;

                    .q_item {
                        margin-bottom: 40px;

                        .q {
                            display: flex;
                            align-items: center;
                            font-size: .25rem;

                            .dian {
                                width: .1rem;
                                height: .1rem;
                                border-radius: 50%;
                                background-color: #0D4090;
                                margin-right: 5px;
                            }
                        }

                        .a {
                            padding: .15rem .15rem .15rem .22rem;
                            font-size: .25rem;
                        }
                    }



                }
            }
        }
    }
</style>