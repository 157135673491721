import {
    Button,
    List,
    Cell,
    CellGroup,
    Icon,
    Popup,
    Swipe,
    SwipeItem,
    Toast,
    Overlay,
    NavBar,
    Pagination,
    ImagePreview
} from 'vant';


export function vant(app) {
    app.use(Button);
    app.use(List)
    app.use(Cell);
    app.use(CellGroup);
    app.use(Icon);
    app.use(Popup);
    app.use(Swipe);
    app.use(SwipeItem);
    app.use(Toast);
    app.use(Overlay);
    app.use(NavBar);
    app.use(Pagination);
    app.use(ImagePreview);
}