<template>
  <div class='index'>
    <div class="h" ref="h" :style="'top:'+top" id="h">
      <div class="logo">
        <img src="../../assets/img/logo2.png" alt="" >
      </div>
      <van-icon @click="openmore" class="more" name="wap-nav" />
    </div>
    <div :style="'height:'+heights+'px'" style="width:100%">
    </div>
    <van-popup v-model:show="show" closeable position="right" :style="{ height: '100%',width:'80%' }">
      <div class="navs">
        <div class="nav_li" v-for="(n,index) in navs" :key="index" @click="openpath(n.path)">
          {{n.name}}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {
      'top': {
        type: String,
        default: '0'
      }
    },
    data() {
      return {
        show: false,
        navs: [{
            id: 1,
            name: '首页',
            path: '/mHome'
          },
          {
            id: 2,
            name: '关于我们',
            path: '/mAbout'
          },

          {
            id: 4,
            name: '医疗团队',
            path: '/mTeam'
          },
          {
            id: 5,
            name: '就诊服务',
            path: '/mMedicalservices'
          },
          {
            id: 6,
            name: '就医指南',
            path: '/mVisitguide'
          },
          {
            id: 7,
            name: '健康资讯',
            path: '/mNews'
          },
          {
            id: 8,
            name: '常见问题',
            path: '/mQuestion'
          },
          {
            id: 3,
            name: '加入我们',
            path: '/mJoin'
          },
          {
            id: 9,
            name: '投诉建议',
            path: '/mFeedback'
          },
        ],
        heights: 0
      };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
      setTimeout(()=>{
      this.getheight()
      },200)
    },
    methods: {
      getheight() {
        let that = this
          that.heights = this.$refs.h.clientHeight
          window.onresize = () => {
            that.heights = this.$refs.h.offsetHeight
          }

      },
      openmore() {
        this.show = true
      },
      openpath(p) {
        this.$router.push(p)
        this.show = false
      }
    },
  };
</script>
<style lang="scss" scoped>
  .index {


    .h {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.26rem 0.32rem;
      background-color: #fff;
      border-bottom: 0.02rem solid #e6e6e6;

      width: 100%;
      box-sizing: border-box;
      position: fixed;
      left: 0;
      z-index: 1000;
      transition: all .3s;

      .logo {
        width: 80%;

        img {
          width: 100%;
          display: block;
        }
      }

      .more {
        font-size: 25px;
      }
    }

    .navs {
      margin-top: 40px;

      .nav_li {
        font-size: .31rem;
        margin: 0 .3rem;
        padding: 0.3rem 0 0.3rem 0.68rem;
        border-bottom: 0.02rem solid #eee;
      }

    }
  }
</style>