<template>
    <div class="index">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(b, index) in banner" :key="index">
                <div class="sw_it"><img :src="b.img" alt="" /></div>
            </van-swipe-item>
        </van-swipe>
        <div class="cons">
            <div class="h_jieshao">
                <h3>让患者更好地拥抱健康生活</h3>
                <p>
                    我们不仅为患者提供更好的线上和线下就医体验还专注于专业的药事服务和持续的病程管理。多元创新的支付方式为患者家庭提供更多选择，减轻经济负担。通过由专家、药师、随访专家、研发人员组成的专业团队，解决诊疗和康复过程中的难点和困难，帮助患者更好地重获健康。
                </p>
            </div>
            <div class="s_m_box">
                <div class="m_item" v-for="(it, index) in m" :key="index" @click="openLink">
                    <div class="m_img"><img :src="it.icon" alt="" /></div>
                    <div class="m_tit">{{ it.tit }}</div>
                    <div class="m_dec">{{ it.dec }}</div>
                </div>
            </div>
            <div class="kstjAndystj_b">
                <div class="kstjAndystj_b_inner">
                    <div class="kstj_b">
                        <div class="tj_header_tits">
                            <div class="t_line"></div>
                            <div class="t_text">科室推荐</div>
                            <div class="t_line"></div>
                        </div>
                        <div class="ks_list_b" style="justify-content: flex-start;">
                            <div class="ks_item" v-for="(it, index) in ks_options" :key="index">
                                <div class="imgs" :class="{imgfk:index==2}">
                                    <img :src="it.icon" :alt="it.text" />
                                </div>
                                <div class="ks_name">{{it.text}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="kstj_b">
                        <div class="tj_header_tits">
                            <div class="t_line"></div>
                            <div class="t_text">医生推荐</div>
                            <div class="t_line"></div>
                        </div>
                        <div class="ks_list_b ks_padd">
                            <div class="ys_item" v-for="(it, index) in doctors" :key="index" @click="opent">
                                <el-image style="width: 80px; height: 80px" class="imgs" :src="it.avatar" fit="cover" />
                                <div class="d_info">
                                    <div class="d_name">{{ it.name }}</div>
                                    <div class="d_zc">
                                        {{ it.dictionaryName }}
                                    </div>
                                </div>
                                <!-- <div class="d_yy">{{ it.doctor_audit.hospital_id_text }}</div> -->
                                <div class="d_sc">{{ it.beGoodAt }}</div>
                                <div class="xf_box">
                                    <div class="sj"></div>
                                    <div class="kuai">在线<br />复诊</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <van-overlay :show="show" @click="show = false" z-index="1001">
            <div class="wrapper">
                <div class="block">
                    <div class="t">您可使用微信扫描小程序码联系医生</div>
                    <img src="../../assets/img/doctorList.png" alt="">
                </div>
            </div>
        </van-overlay>
        <van-overlay :show="showAdv">
            <div class="wrapper2" @click.stop>
                <div class="adv_img">
                    <img :src="popUrl" alt="" @click="goApplet">
                </div>
                <img class="close_icon"
                    src="http://zhenghan-haoyi.oss-cn-shanghai.aliyuncs.com/zhenghanhaoyi/04ea01fc-fab4-4008-9a06-4c3a52ab43b8.png"
                    alt="" @click="showAdv=false">
            </div>
        </van-overlay>
    </div>
</template>

<script>
    import seo from "../../common/seo"
    import departs from "../../common/depart"
    import urlApi from '../../common/config'
    export default {
        metaInfo: {
            ...seo.index
        },
        data() {
            return {
                doctors: [],
                banner: [{
                        img: require("../../assets/img/phone/banner/1.png")
                    },
                    {
                        img: require("../../assets/img/phone/banner/2.png")
                    },
                    {
                        img: require("../../assets/img/phone/banner/3.png")
                    },
                ],
                m: [{
                        id: 1,
                        icon: require("../../assets/img/phone/xsfz.png"),
                        tit: "线上复诊",
                        dec: "优质专家，专业解答",
                    },
                    {
                        id: 2,
                        icon: require("../../assets/img/phone/yygh.png"),
                        tit: "预约挂号",
                        dec: "直通院内专家",
                    },
                    {
                        id: 3,
                        icon: require("../../assets/img/phone/jkzx.png"),
                        tit: "健康咨询",
                        dec: "随时随地解答",
                    },
                ],
                ks_options: [],

                show: false,
                showAdv: false,
                popUrl: ''
            };
        },
        watch: {},
        computed: {},
        created() {
            this.ks_options = departs
        },
        mounted() {
            this.getAdv()
            this.getdoc()

        },
        methods: {
            getAdv() {
                this.popUrl =
                    'http://zhenghan-haoyi.oss-cn-shanghai.aliyuncs.com/zhenghanhaoyi/6453d7ec-988d-4467-807b-353dc51ffa84.png'
                this.showAdv = true
            },
            getdoc() {
                this.axios.get(urlApi.nodeApi + `/pc/getDoctor`, {}).then(res => {

                    this.doctors = res.data.data.data.filter((a, i) => i < 4)
                })
            },
            opent() {
                this.show = true
            },
            goApplet() {
                location.href = 'http://c.nxw.so/80GWr'
            },
            openLink() {
                console.log(123);
                this.opent()

                // location.href = 'http://pgt.zoosnet.net/LR/Chatpre.aspx?id=PGT19709265&lng=cn'
            },
        },
    };
</script>
<style lang="scss" scoped>
    .index {
        line-height: 1.15;

        .sw_it {
            width: 100%;

            img {
                width: 100%;
                display: block;
            }
        }

        .cons {

            // padding: 10px;
            .bg1 {
                background-color: #4c89fe;
                color: #fff !important;
            }

            .h_jieshao {
                text-align: center;

                h3 {
                    font-size: 0.48rem;
                    color: #000;
                    font-weight: 600;
                    line-height: 0.66rem;
                    padding: 0.44rem 0 0.44rem 0;
                }

                p {
                    font-size: 0.28rem;
                    color: #666;
                    line-height: 0.44rem;
                    text-align: justify;
                    padding: 0 0.32rem 0.3rem 0.32rem;
                }
            }

            .s_m_box {
                display: flex;
                align-items: center;
                padding-bottom: 0.2rem;

                .m_item {
                    width: calc(100% / 3);
                    text-align: center;
                    color: #000;

                    .m_img {
                        img {
                            width: 35%;
                        }
                    }

                    .m_tit {
                        font-size: 0.34rem;
                        margin-top: 0.1rem;
                        font-weight: 600;
                    }

                    .m_dec {
                        font-size: 0.18rem;
                    }
                }
            }

            .kstjAndystj_b {
                width: 100%;
                background-color: #f6f6f6;
                padding-bottom: 40px;

                .kstjAndystj_b_inner {
                    width: 100%;
                    margin: 0 auto;

                    .kstj_b {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .tj_header_tits {
                            display: flex;
                            align-items: center;
                            padding: 0.5rem 0;

                            .t_line {
                                width: 1rem;
                                height: 1px;
                                background-color: rgb(146, 146, 146);
                            }

                            .t_text {
                                font-size: 0.35rem;
                                font-weight: 600;
                                margin: 0 10px;
                            }
                        }

                        .ks_padd {
                            padding: 0 0.2rem;
                            box-sizing: border-box;
                        }

                        .ks_list_b {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-end;
                            flex-wrap: wrap;
                            width: 100%;

                            .ks_item {
                                width: calc(100% / 4);
                                margin-bottom: 0.25rem;

                                .imgs {
                                    width: 40px;
                                    margin: 0 auto;

                                    img {
                                        width: 100%;
                                        display: block;
                                    }
                                }

                                .ks_name {
                                    width: 100%;
                                    text-align: center;
                                    font-size: 14px;
                                    margin-top: 10px;
                                }

                                .imgfk {
                                    width: 27px;

                                }
                            }
                        }

                        .ys_item {
                            width: calc(50% - 0.12rem);
                            padding: 0 0.2rem;
                            margin-bottom: 0.3rem;
                            box-sizing: border-box;
                            background-color: #fff;
                            border: 2px solid #d1d1d1;
                            height: 5rem;
                            border-radius: 2px;
                            position: relative;

                            .imgs {
                                width: 40%;
                                margin: 0.23rem auto;
                                border-radius: 50%;
                                overflow: hidden;
                                display: block;

                                img {
                                    width: 100%;
                                    display: block;
                                }
                            }

                            .d_info {
                                display: flex;
                                align-items: flex-end;
                                justify-content: center;
                                line-height: 1;

                                .d_name {
                                    font-size: 0.34rem;
                                    font-weight: 600;
                                }

                                .d_zc {
                                    margin-left: 10px;
                                    color: #7a7a7a;
                                    font-size: 0.25rem;
                                }
                            }

                            .d_yy {
                                text-align: center;
                                margin: 8px 0;
                                color: #000;
                                font-size: 0.25rem;
                            }

                            .d_sc {
                                border-top: 1px solid #d1d1d1;
                                color: #7a7a7a;
                                word-break: break-all;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 4;
                                overflow: hidden;
                                font-size: 0.28rem;
                                padding-top: 0.2rem;
                            }

                            .xf_box {
                                position: absolute;
                                top: 0.3rem;
                                right: -11px;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                                cursor: pointer;
                                font-size: 0.25rem;

                                .sj {
                                    border: 5px solid #165b95;
                                    border-top-color: transparent;
                                    border-right-color: transparent;
                                    width: 0px;
                                    height: 0px;
                                    border-top-left-radius: 3px;
                                }

                                .kuai {
                                    background-color: #6eb9fa;
                                    border-top-left-radius: 5px;
                                    border-bottom-left-radius: 5px;
                                    border-bottom-right-radius: 2px;
                                    padding: 5px 10px;
                                    color: #fff;
                                    line-height: 1.2;
                                }
                            }
                        }
                    }
                }
            }
        }

        .wrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .block {
                width: 80%;
                background-color: #fff;
                border-radius: 5px;
                overflow: hidden;
                padding: 0 20px 10px; 
                box-sizing: border-box;

                .t {
                    font-size: .28rem;
                    text-align: center;
                    padding: .25rem 0;
                }

                img {
                    width: 100%;
                    display: block
                }
            }

        }

        .wrapper2 {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .adv_img {
                width: 100%;

                img {
                    width: 100%;
                    display: block;
                }
            }

            .close_icon {
                width: 35px;
                margin-bottom: 100px;
            }
        }

    }
</style>