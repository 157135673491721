<template>
    <div class="header-wrap" :style="'background:' + bgccolor">
        <div class="header-content">
            <img @click="gopage('/')" :src="!bgccolor ? bgimg1 : bgimg2" class="logo" />
            <ul class="nav-ul">
                <li class="nav-li" :class="{ active: it.path == $route.path }" v-for="(it, index) in nav" :key="index">
                    <a v-if="!it.children" :style="{ color: !bgccolor ? '#fff' : '#000' }"
                        @click="gopage(it.path,it)">{{ it.name }}</a>
                    <el-popover v-else placement="bottom" title="" :visible="it.show" trigger="click"
                        popper-class="p_width">
                        <template #reference>
                            <a :style="{ color: !bgccolor ? '#fff' : '#000' }" @click="clicktit(it)">{{it.name}}</a>
                        </template>
                        <div v-for="(child, ind) in it.children" :key="ind" class="child_b"
                            :class="{ active: child.path == $route.path }">
                            <a class="child_text" @click="gopage(child.path,it)">{{ child.name }}</a>
                        </div>
                    </el-popover>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import {
        computed,
        defineComponent,
        onMounted,
        onUpdated,
        reactive,
        ref,
        toRefs,
        toRaw,
    } from "vue";
    import {
        useRouter
    } from "vue-router";
    export default defineComponent({
        props: {
            bgccolor: {
                type: String,
                default: "",
            },
            fontColor: {
                type: String,
                default: "",
            },
        },
        components: {},
        created() {},
        setup(props, ctx) {
            let r = useRouter();
            let router = toRaw(r);
            const state = reactive({
                bgimg1: require("../../../assets/img/logo.png"),
                bgimg2: require("../../../assets/img/zhlogo.png"),
                nav: [{
                        id: 1,
                        name: "首页",
                        path: "/",
                    },
                    {
                        id: 2,
                        name: "关于我们",
                        path: "/about",
                    },

                    {
                        id: 4,
                        name: "医疗团队",
                        path: "/team",
                    },
                    {
                        id: 5,
                        name: "患者服务",
                        path: "",
                        show: false,
                        children: [{
                                id: 1,
                                name: "就诊服务",
                                path: "/medicalservices",
                            },
                            {
                                id: 2,
                                name: "就医指南",
                                path: "/visitguide",
                            },

                        ],
                    },
                    {
                        id: 7,
                        name: "健康资讯",
                        path: "",
                        show: false,
                        children: [{
                                id: 3,
                                name: "资讯中心",
                                path: "/news",
                            },
                            // {
                            //     id: 4,
                            //     name: "小贴士",
                            //     path: "/tipArticle",
                            // },

                        ],
                    },
                    {
                        id: 8,
                        name: "常见问题",
                        path: "/question",
                    },
                    {
                        id: 3,
                        name: "加入我们",
                        path: "/join",
                    },
                    {
                        id: 9,
                        name: "投诉建议",
                        path: "/feedback",
                    },
                ],
                visible: false
            });
            onMounted(() => {});
            onUpdated(() => {});

            function gopage(p, s) {
                router.push(p);

                s.show = false
            }

            function clicktit(it) {
                state.nav.forEach(e=>{
                    if(e.children&&e.children.length>0){
                        e.show=false
                    }
                })
                it.show=true
            }
            return {
                ...toRefs(state),

                gopage,
                clicktit
            };
        },
    });
    // export default {
    //     props: {
    //         bgccolor: {
    //             type: String,
    //             default: ''
    //         }
    //     },
    //     data() {
    //         return {
    //             nav: [{
    //                     id: 1,
    //                     name: '首页',
    //                     path: '/'
    //                 },
    //                 {
    //                     id: 2,
    //                     name: '关于我们',
    //                     path: '/about'
    //                 },

    //                 {
    //                     id: 4,
    //                     name: '医疗团队',
    //                     path: '/team'
    //                 },
    //                 {
    //                     id: 5,
    //                     name: '就诊服务',
    //                     path: '/medicalservices'
    //                 },
    //                 {
    //                     id: 6,
    //                     name: '就医指南',
    //                     path: '/visitguide'
    //                 },
    //                 // {
    //                 //     id: 7,
    //                 //     name: '健康资讯',
    //                 //     path: '/news'
    //                 // },
    //                 {
    //                     id: 8,
    //                     name: '常见问题',
    //                     path: '/question'
    //                 },
    //                  {
    //                     id: 3,
    //                     name: '加入我们',
    //                     path: '/join'
    //                 },
    //             ]
    //         }
    //     },
    //     mounted() {
    //     },
    //     methods: {
    //         gopage(p) {
    //             this.$router.push(p)
    //         },

    //     }
    // }
</script>

<style lang="scss" scoped>
    .header-wrap {
        width: 100%;
        height: 100px;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 1000;

        .header-content {
            width: 1200px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 auto;
            height: 100px;

            .logo {
                width: 240px;
                cursor: pointer;
            }

            .nav-ul {
                .nav-li {
                    float: left;
                    font-size: 16px;
                    font-weight: 600;
                    color: #333;
                    cursor: pointer;
                    position: relative;
                    height: 100px;
                    padding: 39px 25px;
                    box-sizing: border-box;
                }

                .nav-li.active:after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 2px;
                    background: #134393;
                    margin-top: 7px;
                }

                .nav-li.active a {
                    color: #134393;
                }
            }
        }
    }

    .child_b {
        margin-bottom: 10px;
        text-align: center;
        cursor: pointer;
        position: relative;

        .child_text {
            color: #000;
            font-size: 16px;
        }

        &:nth-last-child(2) {
            margin-bottom: 0;
        }
    }

    .child_b.active:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background: #134393;
        // margin-top: 7px;
        position: absolute;
        left: 0;
    }

    .child_b:last-child {
        margin-bottom: 0;
    }
</style>

<style>
    .p_width {
        min-width: auto !important;
        width: auto !important;
    }
</style>