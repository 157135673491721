export default {
    //首页
    index: {
        title: '正汉互联网医院一站式医疗健康生态系统，您的私人顾问！专家“云”集,在线问诊,在线咨询,网上预约挂号！',
        meta: [{
                name: 'keyWords',
                content: '正汉,互联网医院,智能导诊,医疗软件, AI医疗, 人工智能, 预约挂号, 知识图谱, 医院系统, 医师辅助, 药店系统, 智能导诊,智能诊断,预问诊、智能问药,智能问答,健康咨询等服务'
            },
            {
                name: 'description',
                content: '正汉互联网医院致力打造一站式医疗健康生态系统，秉承着“专业、规范、创新、至善”的理念，为广大患者提供便捷、完善的互联网+医疗健康服务。 凭借“互联网+医疗健康”信息服务体系的支撑，正汉互联网医院扎实推进医疗资源整合，与专家建立协作关系，精准对接、满足群众多层次多样化的健康需求。'
            }
        ]
    },
    //关于我们
    about: {
        title: '关于正汉互联网医院的介绍',
        meta: [{
                name: 'keyWords',
                content: '正汉,互联网医院,医疗服务商，医疗健康'
            },
            {
                name: 'description',
                content: '正汉互联网医院是以互联网+医疗健康为核心的互联网医疗服务商，是国家发布《关于促进“互联网+医疗健康”发展的意见》以来，较早创立、具有合法资质的互联网医院。'
            },
        ]

    },
    //医疗团队
    team: {
        title: '正汉互联网医院推进医疗资源整合，与专家建立协作关系，精准对接、满足群众多层次需求',
        meta: [{
                name: 'keyWords',
                content: '预约专家门诊,在线诊疗,电子处方,在线配药,全方位服务'
            },
            {
                name: 'description',
                content: '正汉互联网医院是国家的试点平台,聚合了全国1900家重点医院,6700位学科带头人,20万副主任以上的医师.提供预约挂号,预约专家门诊,在线诊疗,电子处方,在线配药全方位服务。找大专家,上正汉'
            },
        ]
    },
    // 就诊服务
    service: {
        title: '正汉互联网医院覆盖主任级专家，医生10分钟内快速响应，提供优质问诊挂号服务！',
        meta: [{
                name: 'keyWords',
                content: '在线问诊,在线挂号，科普资讯，专业社群私域服务'
            },
            {
                name: 'description',
                content: '正汉互联网医院是服务医患的互联网医疗平台，是医生和医生集团的互联网云端诊室，为医生提供线上接诊、患者管理、医生教育、电子处方及专业药品服务。'
            },
        ]
    },
    //就医指南
    guide: {
        title: '正汉互联网医院提供智能分诊、在线咨询、预约挂号、在线诊疗、在线购药、双向转诊、慢病管理等服务。',
        meta: [{
                name: 'keyWords',
                content: '智能分诊，在线咨询，预约挂号，在线诊疗，在线购药，双向转诊，慢病管理'
            },
            {
                name: 'description',
                content: '正汉互联网医院在“互联网+医疗健康”领域不断探索，整合资源，通力协作，将线上线下诊疗互换、一站式预约、药品派送等功能上线，打通了患者求医问药的“最后一公里”。'
            },
        ]
    },
    //常见问题
    problem: {
        title: '关于正汉互联网医院咨询费、改签时间、预约医生、上传病历资料等问题解答',
        meta: [{
                name: 'keyWords',
                content: '正汉互联网,咨询费,改签时间,预约医生,上传病历资料'
            },
            {
                name: 'description',
                content: '正汉互联网医院如何预约医生？在医院网站上点击立即预约，或者通过手机微信公众号“正汉互联网医院”首页点击挂号，按照指引即可一步步完成。'
            },
        ]
    },
    //加入我们
    join: {
        title: '正汉互联网医院目前正在招聘商务、研发、运营等相关岗位，期待您的加入。',
        meta: [{
                name: 'keyWords',
                content: '正汉互联网招聘,正汉医院岗位，互联网医院运营'
            },
            {
                name: 'description',
                content: '正汉互联网医院目前正在招聘商务、研发、运营等相关岗位，如有意向欢迎将简历发至 498694540@qq.com我们将尽快与您联系，期待您的加入。'
            },
        ]
    }, //资讯
    news: {
        title: '新闻资讯',
        meta: [{
                name: 'keyWords',
                content: '医院新闻-新闻中心-正汉互联网医院'
            },
            {
                name: 'description',
                content: '医院新闻-新闻中心-正汉互联网医院'
            },
        ]
    },
}