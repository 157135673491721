export default [

    {
        type: '医学岗位',
        tId: 1,
        child: [{
                name: '[科室医生]',
                duty: [],
                qualification: []
            },
            {
                name: '中医科医生 招聘人数100名',
                duty: [],
                qualification: []
            },
            {
                name: '中医男科医生 招聘人数10名',
                duty: [],
                qualification: []
            },
            {
                name: '泌尿外科医生 招聘人数10名',
                duty: [],
                qualification: []
            },
            {
                name: '外科医生 招聘人数100名',
                duty: [],
                qualification: []
            },
            {
                name: '皮肤科医生 招聘人数100名',
                duty: [],
                qualification: []
            },
            {
                name: '妇产科医生 招聘人数100名',
                duty: [],
                qualification: []
            },
            {
                name: '内科医生 招聘人数100名',
                duty: [],
                qualification: []
            },
            {
                name: '肿瘤科医生 招聘人数100名',
                duty: [],
                qualification: []
            },
            {
                name: '精神科医生 招聘人数100名',
                duty: [],
                qualification: []
            },


            {
                name: '康复科医生 招聘人数100名',
                duty: [],
                qualification: [
                    "临床医学相关专业本科学历，主治医师及以上资格；",
                    "具有一定的计算机操作技能；",
                    "高度责任心、服务意识强、工作态度严谨、较强的沟通能力；",
                    "具备良好的医患沟通培训及病情宣教解释培训，普通话标准；",
                    "良好的亲和力与服务意识，为人热情，工作认真细致；",
                    "有医院工作经历、健康管理师优先。",
                ]
            },
            {
                name: '[互联网医院中医医生] 招聘人数500名',
                duty: [
                    "在互联网医疗平台为患者的复诊用药进行接诊开处方",
                    "自带流量在线兼职合作，图文/视频/音频问诊方式",
                    "办公点不限，上线自由，根据自身时间上线接诊即可",
                    "有电脑及通畅的网络"
                ],
                qualification: [
                    "医师资格证、 执业医师证年限满3年(互联网从医资格要求)",
                    "执业范围： 中医科",
                    "需要多点执业",
                    "工作认真负责",
                ],
                time: [
                    "工作时间及地点不限(只要有电脑有时间就可以)"
                ],
                other: [
                    "工作周期： 长期兼职",
                    "工作时间： 不限",
                    "工作时段： 不限",
                    "结算方式： 月结",
                ],

            },

            {
                name: '[医生助理] 招聘人数10名',
                duty: [

                ],
                qualification: [
                    "年龄25-45岁，医学、护理、心理、康复、预防医学等与医学相关专业专科学历均可；",
                    "有护士证、医助等相关资格证，有医院工作经历、健康管理师优先；",
                    "熟悉计算机操作技能；",
                    "高度责任心、服务意识强、工作态度严谨、较强的沟通能力。",
                ]
            },
            {
                name: '[药剂师主管]  招聘人数2名',
                duty: [

                ],
                qualification: [
                    "年龄30 - 45 岁， 大专及以上学历， 取得药师中级职称证， 中医、 中药药学专业， 有药师主管经验1年以上， 熟悉有关药品管理的法律法规， 符合注册要求；",
                    "具备良好的药学道德， 有事业心， 热爱本专业， 坚持学习， 努力专研业务技术；",
                    "良好的亲和力与服务意识， 为人热情， 工作认真细致， 责任心强。",
                ]
            },
            {
                name: '[药剂师] 招聘人数5名',
                duty: [

                ],
                qualification: [
                    "大专以上学历， 中药药学等相关专业， 有医院药师工作经验1年以上， 取得中药药师职称证， 熟悉有关药品管理的法律法规， 符合注册要求；",
                    "良好的亲和力与服务意识， 为人热情， 工作认真细致， 责任心强。",

                ]
            },

        ]
    },
    {
        type: '专业岗位',
        tId: 2,
        child: [{
                name: '[互联网医院中医全科/男科线上诊疗咨询主管] 招聘人数5名',
                duty: [

                ],
                qualification: [
                    "大专以上学历， 医院管理、 医疗护理等相关专业优先， 有服务行业客服质量管理工作经验2年以上；",
                    "了解国家卫生政策法规， 熟悉掌握医院管理、 医疗质量管理、 绩效管理基本知识；",
                    "熟悉医疗护理操作流程， 掌握统计基本知识， 熟悉计算机基本知识；",
                    "具备沟通协调能力， 很强的判断和开拓创新能力， 医学相关知识的学习能力， 医院稽查事务工作的处理能力， 一定的综合分析能力。",
                ]
            },
            {
                name: '[互联网医院咨询客服] 招聘人数100名',
                duty: [

                ],
                qualification: [
                    "工作严谨，认真负责，有亲和力；",
                    "语言表达清晰，善于客户沟通，协调能力好；",
                    "工作认真负责，心理素质好，具有良好的职业道德和团队精神；",
                    "熟练打字，可接受无经验工作者。",
                ]
            },
            {
                name: '[自媒体运营] 招聘人数3名',
                duty: [
                    "负责公众号及公司自有平台的运营和日常内容撰写；",
                    "动态了解平台热点话题，实现账号可持续运营和发展；",
                    "负责平台活动的策划和开展，并做数据分析和统计。",
                ],
                qualification: [
                    "大专及以上学历，品牌管理、广告、新闻等相关专业优先考虑。",
                    "热爱社交媒体，具备较强的新闻、热点敏感性，有较强的文案功底。",
                    "具备良好的沟通表达能力，有独具的创新精神和团队管理意识",
                ]
            },
            {
                name: '[短视频编导] 招聘人数10名',
                duty: [
                    "负责参与策划公司短视频项目的内容制作， 包括不仅限于抖音、 快手等新媒体平台内容输出；",
                    "负责主导并与公司同部门或其他部门协同完成视频拍摄工作， 掌控前期拍摄整理流程，保证视频拍摄的顺利进行；",
                    "负责包括不仅限于视频热点、 视频形式等主要思想输出， 并协助后期进行精剪和包装，保证最后呈现效果；",
                    "擅长人物访谈， 基于访谈对话， 挖掘精彩内容；",
                    "完成领导交办的其他工作。"
                ],
                qualification: [
                    "大专以上学历， 1 年相关工作经验， 有传媒、 新媒体相关专业优先;",
                    "有原创短视频经验者优先， 医疗健康、 泛娱乐领域经验者优先;.",
                    "有较强的选题策划能力和互联网敏锐度， 对短视频内容、 专题栏目的总体策划、 创意、包装等执行能力;",
                    "在视频创意形式或者短视频运营、 新媒体运营方向任意一个方向擅长， 有工作结果展示。",
                ]
            },
            {
                name: '[短视频拍摄剪辑] 招聘人数10名',
                duty: [
                    "熟悉了解抖音后台操作者优先；",
                    "沟通执行力强，按照时间节点完成工作；",
                    "喜欢热爱新媒体事业优先；",
                    "熟悉办office办公软件",
                ],
                qualification: [
                    "工作认真负责， 有效率， 合理利用8小时工作时间完成拍摄剪辑及领导安排的任务；",
                    "熟练使用PrAE等软件;",
                    "有较好的理解能力和沟通能力, 具有独立思考工作的能力和良好的团队合作精神, 有较强的学习能力及吃苦耐劳的工作精神, 能承受一定强度的工作。",
                    "工作经验一年以上， 了解单反使用参数， 有一定的拍摄经验；",
                ]
            },



        ]
    }













]