<template>
    <div class='index'>
        <div class="logo_b logo_b1">
            <img src="../../../assets/img/xf1.png" alt="">
        </div>
        <div class="logo_b logo_b2">
            <img src="../../../assets/img/xf2.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {};
        },
        watch: {},
        computed: {},
        created() {},
        mounted() {},
        methods: {},
    };
</script>
<style lang="scss" scoped>
    .index {
        position: fixed;
        bottom: 10px;
        right: 0;
        z-index: 10000;
        .logo_b {
            width: 119px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            margin-bottom: 10px;
            // box-shadow: 0 0 5px 0 rgba($color: #000000, $alpha: .1);

            img {
                width: 100%;
                display: block;
            }
        }
        .logo_b1 {
            // background-color: #fff !important;
        }
        .logo_b2 {
            // background-color: #fff !important;
        }
    }

    .ewmimgs {
        width: 100%;

        img {
            width: 100%;
            display: block;
        }
    }
</style>