<template>
    <div class="index">
        <div class="screen-wrap">
            <div class="content-wrap">
                <div class="top-wrap"><img class="insurance-banner" data-src="../../assets/img/jiuyi/seekDoctor1.png"
                        src="../../assets/img/jiuyi/seekDoctor1.png" lazy="loaded">
                </div>
                <div class="insurance-wrap">
                    <div class="m-title mt-80" style="text-align: left;">患者服务</div>
                    <div class="pure-text mt-24">
                        可享受全国一线医院的专家服务，进行在线开方购药，可实现足不出户、送药到家。 </div>
                    <div class="m-title mt-40" style="text-align: left;">医生服务</div>
                    <div class="pure-text mt-24">
                        正汉互联网医院是服务医患的互联网医疗平台，是医生和医生集团的互联网云端诊室，为医生提供线上接诊、患者管理、医生教育、电子处方及专业药品服务。 </div>
                    <div class="m-title mt-40" style="text-align: left;">机构服务</div>
                    <div class="pure-text mt-24"> 面向药企：提供真实世界药品研究，通过互联网医院进行患者招募，提供数字营销赋能，方便患者管理。 </div>
                </div>
                <div class="insurance-wrap">
                    <div class="b-title mt-120">正汉互联网医院‧患端服务</div>
                    <div class="advantage-wrap flex">
                        <div><img src="../../assets/img/jiuyi/zxwz2.png" class="advantage-icon">
                            <div class="m-title">在线问诊 快速连接</div>
                            <div class="pure-text mt-32 w-318"> 覆盖主任级专家，专家10分钟内快速响应，提供优质问诊服务
                            </div>
                        </div>
                        <div><img src="../../assets/img/jiuyi/zxgh2.png" class="advantage-icon">
                            <div class="m-title">在线挂号 一键预约</div>
                            <div class="pure-text mt-32 w-318"> 节省到院排队时间，实现省时、 省心、省力 </div>
                        </div>
                    </div>
                    <div class="advantage-wrap flex">
                        <div><img src="../../assets/img/jiuyi/plff2.png" class="advantage-icon">
                            <div class="m-title">品类丰富 安全配送</div>
                            <div class="pure-text mt-32 w-318"> 专业药品库，一对一配药，配送更安全放心 </div>
                        </div>
                        <div><img src="../../assets/img/jiuyi/viphy2.png" class="advantage-icon">
                            <div class="m-title">VIP会员 畅享权益</div>
                            <div class="pure-text mt-32 w-318"> 会员专享10000+医药健康品超低购， 不限次免费图文问诊等超级权益
                            </div>
                        </div>
                    </div>
                    <div class="advantage-wrap flex">
                        <div><img src="../../assets/img/jiuyi/kpzx2.png" class="advantage-icon">
                            <div class="m-title">科普资讯 海量汇聚</div>
                            <div class="pure-text mt-32 w-318"> 超多健康科普，精准推送，随时随地获得想看内容 </div>
                        </div>
                        <div><img src="../../assets/img/jiuyi/vipfw2.png" class="advantage-icon">
                            <div class="m-title">精准私域 VIP服务</div>
                            <div class="pure-text mt-32 w-318"> 专业社群私域服务，专家义诊、直播、 在群坐诊，大夫及时解答疑难，优惠福利会员制尊享 </div>
                        </div>
                    </div>
                </div>
                <div class="seek-medical">
                    <div style="padding: 0px 0.32rem;">
                        <div class="b-title-w">正汉医生app‧医端服务</div>
                        <div class="advantage-wrap flex">
                            <div><img src="../../assets/img/jiuyi/xxpt2.png" class="advantage-icon">
                                <div class="m-title-w">学习平台</div>
                                <div class="pure-text-w mt-32 w-318">
                                    汇聚国内外专科医疗机构，共同打造专科的线上学习平台，包括专家直播等，实时分享治疗理念，提高临床诊疗水平。 </div>
                            </div>
                            <div><img src="../../assets/img/jiuyi/hzgl2.png" class="advantage-icon">
                                <div class="m-title-w">患者管理</div>
                                <div class="pure-text-w mt-32 w-318">
                                    建立互联网医患连接，实现患者分组管理，提升诊疗效率，打造医生口碑。 </div>
                            </div>
                        </div>
                        <div class="advantage-wrap flex">
                            <div><img src="../../assets/img/jiuyi/msys.png" class="advantage-icon">
                                <div class="m-title-w">品牌医生</div>
                                <div class="pure-text-w mt-32 w-318">
                                    拥有涵盖医疗、药品、服务、信息等多领域的专业运营团队，在诊疗核心领域配备“正医健康助理”，打造优质互联网医疗体验。帮助医生建立和提升个人品牌，提升门诊量和收入。
                                </div>
                            </div>
                            <div><img src="../../assets/img/jiuyi/ppxc2.png" class="advantage-icon">
                                <div class="m-title-w">品牌宣传</div>
                                <div class="pure-text-w mt-32 w-318">
                                    为符合条件的专科医生进行品牌宣传，通过内容传播、服务覆盖以及奖项评选、个人专访等形式，结合权威媒体，联合发布发声。让合作医生得到更广泛的认可、个人品牌价值得以放大。
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import seo from "../../common/seo"
    export default {
        metaInfo: {
            ...seo.service
        },
        components: {},
        data() {
            return {

            }
        },
        mounted() {
            this.$root.Headerbgc = '#fff'
        }

    }
</script>

<style lang="scss" scoped>
    .index {
        .screen-wrap {
            width: 100%;
            background: rgb(255, 255, 255);

            // padding-bottom: 60px;
            .content-wrap {
                width: 100%;
                margin: 0 auto;

                .top-wrap {
                    width: 100%;
                    position: relative;

                    .insurance-banner {
                        width: 100%;
                        display: block;
                    }

                    .top-con {
                        position: absolute;
                        top: 104px;
                        right: 73px;
                        text-align: left;

                        .top-title {
                            font-size: 36px;
                            color: #333;
                            line-height: 50px;
                        }

                        .top-text {
                            font-size: 16px;
                            color: #777;
                            line-height: 30px;
                            margin-top: 20px;
                            width: 512px;
                        }
                    }
                }

                .b-title {
                    color: #000;
                    line-height: .67rem
                }

                .b-title,
                .b-title-w {
                    font-size: .48rem;
                    font-weight: 600
                }

                .b-title-w {
                    color: #fff
                }

                .m-title {
                    color: #000
                }

                .m-title,
                .m-title-w {
                    font-size: .32rem;
                    font-weight: 600
                }

                .m-title-w {
                    color: #fff
                }

                .pure-text {
                    color: #666;
                    font-family: PingFangSC-Regular, PingFang SC
                }

                .pure-text,
                .pure-text-w {
                    font-size: .28rem;
                    font-weight: 400;
                    line-height: .4rem;
                    text-align: justify
                }

                .pure-text-w {
                    color: #fff
                }

                .mt-40 {
                    margin-top: .4rem
                }

                .mt-120 {
                    margin-top: 1.2rem
                }

                .mt-32 {
                    margin-top: .32rem
                }

                .mt-80 {
                    margin-top: .8rem
                }

                .mt-24 {
                    margin-top: .24rem
                }

                .flex {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: -webkit-flex;
                    display: flex
                }

                .w-318 {
                    width: 3.18rem
                }

                .insurance-banner {
                    width: 100%;
                    display: block
                }



                .insurance-wrap .health-img {
                    width: 100%;
                    margin-top: .8rem
                }

                .banner_wrap.p_top05 {
                    padding-top: 72.5%
                }

                .footer_nav {
                    border-top: none
                }

                .insurance-banner {
                    width: 100%;
                    display: block
                }

                .insurance-wrap {
                    padding: 0 .34rem .81rem;
                    text-align: center;

                }

                .insurance-wrap .advantage-wrap {
                    margin-top: .8rem;
                    -webkit-box-pack: justify;
                    -webkit-justify-content: space-between;
                    -ms-flex-pack: justify;
                    justify-content: space-between
                }

                .insurance-wrap .advantage-wrap .advantage-icon {
                    width: 1rem;
                    margin-bottom: .24rem
                }

                .health-img {
                    width: 100%
                }

                .medication-title {
                    color: #000;
                    font-size: .28rem;
                    margin-top: .16rem
                }

                .banner_wrap.p_top04 {
                    padding-top: 42.667%
                }

                .seek-box {
                    padding: .24rem 0;
                    text-align: center;
                    background: #fafafa;
                    border-radius: .32rem;
                    margin-top: .4rem
                }

                .add-img {
                    width: 100%;
                    margin-top: .8rem
                }

                .insurance-banner {
                    width: 100%;
                    display: block
                }

                .insurance-wrap {
                    padding: 0 .34rem 1.2rem
                }

                .advantage-wrap {
                    margin-top: .8rem
                }

                .advantage-wrap-m,
                .advantage-wrap {
                    -webkit-box-pack: justify;
                    -webkit-justify-content: space-between;
                    -ms-flex-pack: justify;
                    justify-content: space-between
                }

                .advantage-wrap-m {
                    margin-top: .4rem
                }

                .advantage-icon {
                    width: 1rem;
                    margin-bottom: .24rem
                }

                .health-img {
                    width: 100%;
                    margin-top: .8rem
                }

                .medication-title {
                    color: #000;
                    font-size: .28rem;
                    margin-top: .16rem
                }

                .seek-medical {
                    width: 100%;
                    padding: 1.2rem 0;
                    background: #4C89FE;
                    text-align: center;
                }

                .seek-title {
                    font-size: .32rem;
                    font-weight: 500;
                    color: #000
                }

                .seek-title:after {
                    content: "";
                    display: block;
                    width: .8rem;
                    height: .04rem;
                    background: #00aa96;
                    margin: 0 auto;
                    margin-top: .1rem
                }

                .operate-img {
                    width: 3.69rem;
                    height: 3.15rem;
                    margin: .8rem auto
                }



                .bottom-wrap {
                    width: 100%;
                    background: #fafafa
                }

                .seek-circle {
                    width: 3.27rem
                }

                .seek-text-new {
                    padding: .32rem;
                    font-weight: 400;
                    color: #000;
                    line-height: .4rem;
                    font-size: .28rem;
                    background: #fff;
                    width: 3.27rem;
                    box-sizing: border-box;
                    text-align: center
                }

                .bottom-wrap .medication-title {
                    color: #000
                }

                .banner_wrap.p_top02 {
                    padding-top: 56.338%
                }

                .banner_wrap.p_top03 {
                    padding-top: 56.305%
                }

                .banner_wrap.p_top07 {
                    padding-top: 0
                }

                .banner_wrap.p_top08 {
                    padding-top: 66.667%
                }

                .insurance-banner {
                    width: 100%;
                    display: block
                }

                .insurance-wrap {
                    padding: 0 .34rem 1.2rem
                }

                .advantage-wrap {
                    margin-top: .8rem;
                    -webkit-box-pack: justify;
                    -webkit-justify-content: space-between;
                    -ms-flex-pack: justify;
                    justify-content: space-between
                }

                .advantage-icon {
                    width: 1rem;
                    margin-bottom: .24rem
                }

                .health-img {
                    width: 100%;
                    margin-top: .8rem
                }

                .medication-title {
                    color: #000;
                    font-size: .28rem;
                    margin-top: .16rem
                }

                .operate-img {
                    width: 3.69rem;
                    display: block;
                    margin: .8rem auto
                }

                .seek-text {
                    color: #666;
                    font-size: .28rem;
                    font-weight: 400;
                    line-height: .24rem;
                    text-align: center
                }

                .bottom-wrap .medication-title {
                    color: #000;
                    line-height: .4rem
                }

                .seek-circle {
                    width: 2.8rem;
                    height: 2.8rem;
                    background: rgba(0, 190, 140, .1);
                    border-radius: 50%;
                    font-size: .32rem;
                    color: #000;
                    padding: .73rem .6rem;
                    line-height: .45rem;
                    box-sizing: border-box
                }


                .m-title2 {
                    font-size: .48rem
                }

                .banner_wrap.p_top07 {
                    padding-top: 0
                }

                .detail-wrap {
                    padding: 0 .34rem .8rem
                }

                .detail-wrap p {
                    line-height: .4rem !important
                }

                .detail-wrap img {
                    max-width: 100% !important;
                    display: block;
                    margin-top: .4rem !important
                }
            }
        }
    }
</style>